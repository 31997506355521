import { gql } from 'apollo-boost';

const VOTE_DETAILED_RESULTS = gql`
    query detailedVotes($module_id: Int!){
        detailedVotes(module_id:$module_id){
            tracking_id
            answer_id
            pl_type
            createdAt
            contact_name
            contact_email
            subscribe_to_newsletter
        }
    }
`;

export default VOTE_DETAILED_RESULTS;
