import { gql } from 'apollo-boost';

const BAD_WORDS = gql`
    query badWords{
        badWords {
            key
            value
        }
    }
`;

export default BAD_WORDS;
