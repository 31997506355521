import { gql } from 'apollo-boost';

const MODULE_ADD = gql`
    mutation moduleCreate($payload: ModuleCreateInput!){
        createModule(payload: $payload) {
            id
            episode_id
            title
        }
    }
`;

export default MODULE_ADD;
