import { gql } from 'apollo-boost';

const SETTING_ADD_UPDATE = gql`
    mutation createOrUpdateSetting($payload: SettingInput!){
        createOrUpdateSetting(payload: $payload) {
            key
            value
        }
    }
`;

export default SETTING_ADD_UPDATE;
