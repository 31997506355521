import { gql } from 'apollo-boost';

const LIST_PUBLIC_EVENTS = gql`
    query listPublicEvents($client_id: Int!){
        events: shows(client_id: $client_id) {
            id
            name
            domain_name
            description
            publicEvent: publicShow {
                id
                session: episode {
                    id
                    modules { id, type }
                }
            }
        }
    }
`;

export default LIST_PUBLIC_EVENTS;
