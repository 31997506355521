import React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  gap: 4px;
  margin-bottom: 24px;
`;

const PresenterHead = ({ title, subtitle }) => {
  if (!title && !subtitle) return null;

  return (
    <Container>
      <h1>{title}</h1>
      {subtitle && (
        <h2>{subtitle}</h2>
      )}
    </Container>
  );
};

PresenterHead.Container = Container;

export default PresenterHead;
