import { useEffect } from 'react';
import useAnimationState, { AnimationStatus } from './useAnimationState';

const disposableTimeout = (handler, d) => {
  const timeout = setTimeout(handler, d);
  return () => {
    clearTimeout(timeout);
  };
};

export default function useLoopingAnimationState({ duration, delay, enabled }) {
  const [state, startAnimation] = useAnimationState({ duration, enabled });

  // Start animation from idle
  useEffect(() => {
    if (!enabled) return;
    if (state === AnimationStatus.Idle) return disposableTimeout(startAnimation, 0);
    if (state === AnimationStatus.Finished) return disposableTimeout(startAnimation, delay);

    return () => {};
  }, [state, startAnimation, delay, enabled]);

  return [state, startAnimation];
}
