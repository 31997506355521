/**
 * @param {string} hex
 */
const hexToRGB = (hex) => {
  const matches = hex.match(/[0-9a-f]{2}/ig);
  return matches.map((item) => Number.parseInt(item, 16));
};

/**
 * @param {[number, number, number]} rgb
 */
const rgbToHex = (rgb) => rgb.map(
  (c) => Math.max(Math.min(255, c), 0).toString(16).padStart(2, '0'),
).join('');

/**
 * A stupid but simple way of finding a color that is "closest to black"
 * @param { [number, number, number] } colors
 * @returns { [number, number, number] }
 */
const closestToBlack = (...colors) => {
  let closest = colors[0];

  for (let i = 1; i < colors.length; i++) {
    const current = colors[i];
    const sum = closest.reduce((s, a) => s + a, 0);
    const currentSum = current.reduce((s, a) => s + a, 0);
    if (currentSum < sum) {
      closest = current;
    }
  }

  return closest;
};

/**
 * @param {string} defaultColor
 * @param {string} key
 */
const colorProp = (defaultColor, key = 'color') => (props) => {
  if (key in props) return `#${props[key]}`;
  return defaultColor;
};

export default { hexToRGB, rgbToHex, closestToBlack, colorProp };
