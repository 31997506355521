import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

export default function useApiQuery(query, dataKey, initialArgs = [], mapVariables = () => undefined) {
  const [result, setResult] = useState(undefined);
  const [loadResult, { data, loading: dataLoading, error, called }] = useLazyQuery(query, { fetchPolicy: 'no-cache' });

  const lastUpdate = useMemo(() => {
    if (!result) return false;
    if (!result.updated_at) return false;
    return result.updated_at;
  }, [result]);

  // Load the result from the response
  useEffect(() => {
    if (dataLoading) return;
    if (!called) return;
    if (!data) {
      setResult(null);
      return;
    }

    const response = data[dataKey];
    if (!response) return;
    if (lastUpdate && lastUpdate === response.updated_at) return;

    setResult(response);
  }, [called, dataLoading, error, data, lastUpdate, setResult]);

  useEffect(() => {
    if (called) return;
    if (!initialArgs?.length) return;

    const variables = mapVariables(...initialArgs);
    if (!variables) return;

    loadResult({ variables });
  }, [called, loadResult, mapVariables, ...initialArgs]);

  const loading = useMemo(() => {
    if (result) return false;
    if (data) return false;
    return dataLoading;
  }, [result, dataLoading, data]);

  return [result, {
    load: useCallback((...args) => {
      if (args.length) {
        const variables = mapVariables(...args);
        if (!variables) return;

        loadResult({ variables });
        return;
      }

      const variables = mapVariables(...initialArgs);
      if (!variables) return;

      loadResult({ variables });
    }, [mapVariables, loadResult]),
    loading,
  }];
}
