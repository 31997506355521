import { useCallback, useEffect, useMemo, useReducer } from 'react';

const useAutosaveState = (enabled = true) => useReducer(
  (prevState, action) => {
    if (!enabled) return 'DISABLED';

    switch (action.type) {
      case 'CHANGED':
        return { state: 'CHANGED', changed: Date.now() };
      case 'SAVING':
        return { state: 'SAVING' };
      case 'SAVED':
        return { state: 'SAVED' };
      default:
      case 'IDLE':
        return { state: 'IDLE' };
    }
  },
  enabled ? { state: 'IDLE' } : { state: 'DISABLED' },
);

export default function useAutoSavingForm(submitFunction = () => Promise.resolve(), {
  delay = 1000,
  enabled = true,
}) {
  const [{ state, changed }, dispatchState] = useAutosaveState(enabled);

  const handleSubmit = useCallback(async () => {
    if (!enabled) return;
    try {
      dispatchState({ type: 'SAVING' });
      await submitFunction();
      dispatchState({ type: 'SAVED' });
    } catch (error) {
      dispatchState({ type: 'ERROR', error });
    }
  }, [submitFunction, dispatchState]);

  // Set state from CHANGED to SAVING after delay
  useEffect(() => {
    if (!enabled) return () => {};
    if (state !== 'CHANGED') return () => {};
    const timeout = setTimeout(handleSubmit, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [enabled, changed, state, delay, handleSubmit]);

  // Set state from SAVED to IDLE after 2.5 seconds
  useEffect(() => {
    if (state !== 'SAVED') return () => {};

    const timeout = setTimeout(
      () => dispatchState({ type: 'IDLE' }),
      2500,
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [state, dispatchState]);

  const handleChange = useCallback(() => {
    if (!enabled) return;
    dispatchState({ type: 'CHANGED' });
  }, [dispatchState]);

  const eventHandlers = useMemo(() => {
    ['onChange', 'onBlur'].reduce(
      (handlers, event) => ({ [event]: handleChange }),
      {},
    );
  }, [handleChange]);

  return [eventHandlers, handleChange, state];
}
