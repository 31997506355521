import { useMemo } from 'react';
import { ModuleType } from '../../enums/module.enums';

const ModuleFields = {
  title: [
    ModuleType.Vote,
    ModuleType.Reaction,
    ModuleType.Comments,
    ModuleType.QuestionsAndAnswers,
    ModuleType.WordCloud,
    ModuleType.Moodmeter,
    ModuleType.ProductLaunch,
  ],
  subtitle: [
    ModuleType.Vote,
    ModuleType.Reaction,
    ModuleType.Comments,
    ModuleType.QuestionsAndAnswers,
    ModuleType.WordCloud,
    ModuleType.Moodmeter,
  ],
  subtitle_ended: [
    ModuleType.Vote,
    ModuleType.Reaction,
    ModuleType.Comments,
    ModuleType.QuestionsAndAnswers,
    ModuleType.WordCloud,
    ModuleType.Moodmeter,
  ],
  subtitle_not_started: [
    ModuleType.Vote,
    ModuleType.Reaction,
    ModuleType.Comments,
    ModuleType.QuestionsAndAnswers,
    ModuleType.WordCloud,
    ModuleType.Moodmeter,
  ],
  subtitle_results: [
    ModuleType.Vote,
    ModuleType.Moodmeter,
  ],
  show_results: [
    ModuleType.Vote,
  ],
  show_all_comments: [
    ModuleType.Comments,
    ModuleType.QuestionsAndAnswers,
  ],
  show_new_comments: [
    ModuleType.Comments,
    ModuleType.QuestionsAndAnswers,
  ],
  image: [
    ModuleType.Vote,
    ModuleType.Comments,
    ModuleType.QuestionsAndAnswers,
    ModuleType.Moodmeter,
  ],
  banner_image: [
    ModuleType.ProductLaunch,
  ],
  subtype: [
    ModuleType.Vote,
  ],
  collect_contact_details: [
    ModuleType.Vote,
  ],
  vote_limit: [
    ModuleType.Vote,
    ModuleType.Reaction,
  ],
  is_paid: [
    ModuleType.Vote,
  ],
  show_total_count: [
    ModuleType.Vote,
  ],
  reactions: [
    ModuleType.Reaction,
  ],
  header_text: [
    ModuleType.ProductLaunch,
  ],
  cta_text: [
    ModuleType.Vote,
    ModuleType.ProductLaunch,
  ],
  moodmeter_labels: [
    ModuleType.Moodmeter,
  ],
};

// eslint-disable-next-line import/prefer-default-export
export function useModuleFields(moduleType) {
  return useMemo(
    () => Object.entries(ModuleFields)
      .reduce(
        (result, [key, supportedModules]) => ({
          ...result,
          [key]: supportedModules.includes(moduleType),
        }),
        {},
      ),
    [moduleType],
  );
}
