import { gql } from 'apollo-boost';

const MODULE_CLICKS = gql`
    query productLaunchResults($module_id: Int!){
        productLaunchResults(module_id:$module_id){
            totalLive
            totalEnded
        }
    }
`;

export default MODULE_CLICKS;
