import React, { useState, useEffect, useMemo } from 'react';
import Textarea from 'react-textarea-autosize';
import { useMutation } from '@apollo/react-hooks';
import { ModuleType } from '../../../enums/module.enums';
import MODERATED_COMMENT_ADD from '../../../graphql/mutations/moderatedCommentAdd';
import useLogin from '../../../hooks/useLogin';
import Comment from '../Comments/NewComment';
import * as Styled from './AddComment.styled';

const AddComment = ({ module_id, type, replyingComment, onSubmit, moderatorColor }) => {
  const maxCommentLength = 200;
  const [comment, setComment] = useState('');
  const [charCount, setCharCount] = useState(0);
  const fieldCommentContainerClassList = ['field', 'field__comment'];
  const [addModeratedComment] = useMutation(MODERATED_COMMENT_ADD);
  const { user } = useLogin();

  useEffect(() => {
    setCharCount(comment.length);
  }, [comment]);

  const handleSubmit = async () => {
    try {
      const variables = {
        payload: {
          module_id,
          text: comment,
          parent_id: replyingComment ? replyingComment.id : 0,
        },
      };
      const result = await addModeratedComment({
        variables,
      });

      if (result) {
        onSubmit();
        setComment('');
      }
    } catch (err) {
      // deal with error
    }
  };

  const placeholder = useMemo(() => {
    if (replyingComment) {
      return 'Enter your reply';
    }

    if (type === 'questions_answers') {
      return 'Enter your question';
    }

    return 'Enter your comment';
  }, [type, replyingComment]);

  return (
    <>
      <header className="page-header">
        <h2>Add {type === ModuleType.Comments ? 'comment' : 'question'}</h2>
      </header>
      <div>
        Your {type === ModuleType.Comments ? 'comments' : 'questions'} will be posted under the
        <span className="comment-moderator-name"> {user.username || user.fullname} </span>
        name
      </div>
      <div className="add-comment">
        <div className="field__comment-container">
          <div className={fieldCommentContainerClassList.join(' ')}>
            <Textarea
              placeholder={placeholder}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="comment-area"
              maxLength={maxCommentLength}
            />
          </div>
          <div className="field field__submit">
            <button
              type="button"
              onClick={handleSubmit}
              className={comment.length < 1 ? 'disabled' : undefined}
              disabled={comment.length < 1}
            >
              {comment.length > 0
                && 'Send '}
              <i className="fas fa-paper-plane" />
            </button>
          </div>
        </div>
        <Styled.ReplyContainer visible={replyingComment}>
          {replyingComment && (
            <Styled.ReplyWrapper>
              <span className="label__replying-to">Replying to</span>
              <Comment
                comment={replyingComment}
                moderatorColor={moderatorColor}
                showControls={false}
                showReplies={false}
              />
            </Styled.ReplyWrapper>
          )}
        </Styled.ReplyContainer>
        <div className="field field__char-count">
          <span className="chars-remaining">{charCount}</span>
          /
          <span className="max-chars">{maxCommentLength}</span>
        </div>
      </div>
    </>
  );
};

export default AddComment;
