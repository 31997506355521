import React from 'react';
import styled from 'styled-components/macro';

import PresenterMenu from './PresenterMenu';
import useImageUrl from '../../hooks/utils/useImageUrl';

import votemoLogo from '../../assets/img/logo_votemo.png';
import { useEvent } from '../../hooks/providers/events';

const Header = styled.header`
  --height: 4rem;

  flex-grow: 0;
  height: var(--height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 0 32px;
  z-index: 1;

  img {
    --max-height: calc(var(--height, 4rem) - 1rem);
    --max-width: calc(calc(var(--max-height) / 16) * 50);

    max-height: var(--max-height);
    max-width: var(--max-width);
    object-fit: cover;
  }
`;

const PresenterHeader = () => {
  const [event] = useEvent();
  const eventImage = useImageUrl(event?.image);

  if (!event) return null;

  return (
    <Header>
      <img alt="Votemo Logo" src={eventImage || votemoLogo} />
      <PresenterMenu />
    </Header>
  );
};

export default PresenterHeader;
