import { gql } from 'apollo-boost';

const OPTION_ADD = gql`
    mutation optionCreate($payload: OptionCreateInput!){
        createOption(payload: $payload) {
            id
        }
    }
`;

export default OPTION_ADD;
