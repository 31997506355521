import { useCallback, useMemo } from 'react';
import { useSessionContext } from '../../providers/SessionProvider';

export function useSession() {
  const { session, loading, load } = useSessionContext();
  return [session, { loading, load }];
}

export function useSessionModules(published = true) {
  const [session, { loading, load: loadSession }] = useSession();
  const modules = useMemo(() => {
    if (!session) return [];
    if (!published) return session.modules;
    return session.modules.filter((module) => module.published);
  }, [session, published]);
  const reloadModules = useCallback(() => {
    if (!session) return;
    loadSession(session.id);
  }, [session, loadSession]);

  return [modules, { loading, reloadModules }];
}
