import React from 'react';
import styled from 'styled-components/macro';
import { CircularProgressBar } from '@tomik23/react-circular-progress-bar';

import PresenterHead from '../../PresenterHead';

import useMoodMeterResult from '../../../../hooks/api/modules/useMoodMeterResult';
import { useEventColors } from '../../../../hooks/useEventColors';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export default function MoodMeterPresenter({ module }) {
  const colors = useEventColors();
  const [result] = useMoodMeterResult(module.id);

  return (
    <Container>
      <PresenterHead title={module.title} subtitle={module.subtitle} />
      <CircularProgressBar
        percent={result}
        colorCircle={`#${colors.secondary}`}
        colorSlice={`#${colors.primary}`}
        fontColor={`#${colors.primary}`}
        fontWeight={700}
        size={500}
        round
      />
    </Container>
  );
}
