import { gql } from 'apollo-boost';

const API_KEY_BY_MODULE = gql`
    query api_key_by_module($module_id: Int!){
        module(module_id: $module_id) {
            id
            session: episode {
                id
                event: show {
                    id
                    client {
                        id
                        api_key
                    }
                }
            }
        }
    }
`;

export default API_KEY_BY_MODULE;
