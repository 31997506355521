import { gql } from 'apollo-boost';

const CLIENT_MODERATORS = gql`
    query client($client_id: Int!){
        client(client_id: $client_id) {
            moderators {
                id
                first_name
                last_name
            }
        }
    }
`;

export default CLIENT_MODERATORS;
