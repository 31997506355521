import { gql } from 'apollo-boost';

const CLIENT_DELETE = gql`
    mutation clientDelete($client_id: Int!){
        deleteClient(client_id: $client_id) {
            id
        }
    }
`;

export default CLIENT_DELETE;
