import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import UPDATE_MODULE from '../../graphql/mutations/updateModule';
import OPTION_ADD from '../../graphql/mutations/optionAdd';
import OPTION_UPDATE from '../../graphql/mutations/optionUpdate';
import useLogin from '../../hooks/useLogin';
import BreadCrumbs from '../../components/Breadcrumbs';
import ModuleForm from '../../components/ModuleForm/ModuleForm';
import ListOptions from '../../components/Module/listOptions';
import { useMappedParams } from '../../hooks/utils';
import { ModuleType } from '../../enums/module.enums';
import { ModuleProvider } from '../../providers/ModuleProvider';
import { EventProvider } from '../../providers/EventProvider';
import { SessionProvider } from '../../providers/SessionProvider';
import { useModule } from '../../hooks/providers/modules';
import { useEvent } from '../../hooks/providers/events';
import { useSession } from '../../hooks/providers/sessions';
import { RouteBuilders } from '../../routes';

const UpdateModulePage = () => {
  const { eventId, sessionId, moduleId } = useMappedParams({
    eventId: (params) => +params.event_id,
    sessionId: (params) => +params.session_id,
    moduleId: (params) => +params.module_id,
  });
  return (
    <EventProvider eventId={eventId} refetch={false}>
      <SessionProvider sessionId={sessionId} refetch={false}>
        <ModuleProvider moduleId={moduleId} refetch={false}>
          <ModuleFormWrapper />
        </ModuleProvider>
      </SessionProvider>
    </EventProvider>
  );
};

// TODO: Clean up
function ModuleFormWrapper() {
  const { isModerator } = useLogin();
  const [event] = useEvent();
  const [session] = useSession();
  const [module, { load: loadModule }] = useModule();
  // local state variables
  const [apiError, setApiError] = useState('');
  const [moduleSubType, setModuleSubType] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subTypeChanged, setSubTypeChanged] = useState(false);

  // TODO: graphql
  const [updateOption] = useMutation(OPTION_UPDATE, {
    refetchQueries: ['module'],
  });
  const [addOption] = useMutation(OPTION_ADD, {
    refetchQueries: ['module'],
  });

  useEffect(() => {
    if (!module) return;
    if (module.type !== ModuleType.Vote) return;
    setModuleSubType(module.subtype);
  }, [module, setModuleSubType]);

  const breadcrumbs = useMemo(() => {
    if (!event) {
      return [];
    }

    if (!session) {
      return [
        {
          breadcrumbName: event.name,
          path: RouteBuilders.ofEvent({ eventId: event.id }),
        },
      ];
    }

    if (!module) {
      return [
        {
          breadcrumbName: event.name,
          path: RouteBuilders.ofSession({
            eventId: event.id,
            sessionId: session.id,
          }),
        },
        {
          breadcrumbName: session.name,
          path: RouteBuilders.ofSession({
            eventId: event.id,
            sessionId: session.id,
          }),
        },
      ];
    }

    return [
      {
        breadcrumbName: event.name,
        path: RouteBuilders.ofSession({
          eventId: event.id,
          sessionId: session.id,
        }),
      },
      {
        breadcrumbName: session.name,
        path: RouteBuilders.ofSession({
          eventId: event.id,
          sessionId: session.id,
        }),
      },
      {
        breadcrumbName: module.title,
        path: RouteBuilders.ofModule({
          eventId: event.id,
          sessionId: session.id,
          moduleId: module.id,
        }),
      },
    ];
  }, [event, session, module]);
  // TODO
  const [updateModule] = useMutation(UPDATE_MODULE);
  const processOldReactions = async (payload) => {
    const { options } = payload;
    const previousOptions = module.options;
    await Promise.all(previousOptions.map(async (previousOption) => {
      if (!options.includes(previousOption.title) && previousOption.published) {
        await updateOption({
          variables: {
            option_id: previousOption.id,
            payload: {
              published: false,
            },
          },
        });
      }
      if (options.includes(previousOption.title) && !previousOption.published) {
        await updateOption({
          variables: {
            option_id: previousOption.id,
            payload: {
              published: true,
            },
          },
        });
      }
    }));
    return true;
  };

  const createNewReactions = async (payload) => {
    const { options } = payload;
    const previousOptions = module.options;
    const previousOptionsHelper = previousOptions.map(({ title }) => title);
    await Promise.all(options.map(async (option) => {
      if (!previousOptionsHelper.includes(option)) {
        await addOption({
          variables: {
            payload: {
              module_id: module.id,
              title: option,
              subtitle: option,
              published: true,
              weight: 0,
              image: option,
            },
          },
        });
      }
    }));

    return true;
  };

  const onSubmit = async (payload) => {
    setIsSubmitting(true);
    const { option, options, ...updateData } = payload;

    // @todo: Refactor into form before submit to set default values, if they don't exist
    // This right here, is quite fucky
    // Doing this, because some modules don't have these fields, but database is set up to not allow NULL values
    if (payload.price) {
      updateData.price = parseFloat(payload.price.toString().replace('€ ', ''));
    } else {
      updateData.price = 0;
    }
    if (payload.megavote_price) {
      updateData.megavote_price = parseFloat(payload.megavote_price.toString().replace('€ ', ''));
    } else {
      updateData.megavote_price = 0;
    }
    if (payload.megavote_amount) {
      updateData.megavote_amount = parseInt(payload.megavote_amount, 10);
    } else {
      updateData.megavote_amount = 0;
    }
    if (!payload.show_results) {
      updateData.show_results = false;
    }
    if (!payload.show_total_count) {
      updateData.show_total_count = false;
    }
    try {
      await updateModule({
        variables: {
          module_id: module.id,
          payload: updateData,
        },
      });
      await loadModule(module.id);
      setSubTypeChanged(false);
      if (payload.type !== 'reaction') {
        setIsSubmitting(false);
      } else {
        // First let's unpublish/publish old options
        const oldReactions = await processOldReactions(payload);
        // Now let's add new options
        const create = await createNewReactions(payload);
        if (oldReactions && create) {
          setIsSubmitting(false);
        }
      }

      message.success('Module saved');
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        setApiError(err.graphQLErrors[0].message);
      } else {
        setApiError('Unknown moduleError occurred');
      }
    }
  };

  if (!module) return null;

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      <ModuleForm
        type="update"
        onSubmit={onSubmit}
        error={apiError}
        module={module}
        setSubTypeChanged={setSubTypeChanged}
        isSubmitting={isSubmitting}
      />
      {module.type === ModuleType.Vote && !isModerator && (
        <ListOptions
          subTypeChanged={subTypeChanged}
          moduleSubType={moduleSubType}
          module_id={module.id}
        />
      )}
    </div>
  );
}

export default UpdateModulePage;
