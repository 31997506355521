import { gql } from 'graphql-tag';

const LIST_ALL_EMOJIS = gql`
  query {
    emojis {
      id
      name
      emojiPackId
    }
  }
`;
const FIND_EMOJI_PACK = gql`
  query ($id: Int!) {
    emojiPack(id: $id) {
      id
      name
      description
      availableByDefault
      emojis {
        id
        name
      }
    }
  }
`;
const LIST_ALL_EMOJI_PACKS = gql`
  query {
    emojiPacks {
      id
      name
      description
      availableByDefault
      visibleByDefault
      emojis {
        id
        name
      }
    }
  }
`;

export {
  LIST_ALL_EMOJI_PACKS,
  FIND_EMOJI_PACK,
  LIST_ALL_EMOJIS,
};
