import { gql } from 'apollo-boost';

const UPDATE_SESSION = gql`
    mutation updateSession($session_id: Int!, $payload: EpisodeUpdateInput!){
        session: updateEpisode(episode_id: $session_id, payload: $payload) {
            id
            name
        }
    }
`;

export default UPDATE_SESSION;
