import React from 'react';
import UserMenu from './UserMenu';
import AdminClientSelect from './AdminClientSelect';
import logo from '../../assets/img/logo_votemo.png';

const Header = () => (
  <header className="header">
    <div className="logo">
      <img alt="Votemo Logo" src={logo} width="128" height="24" />
    </div>
    <AdminClientSelect />
    <div id="user-menu" className="user">
      <UserMenu />
    </div>
  </header>
);

export default Header;
