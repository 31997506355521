import { gql } from 'apollo-boost';

const USER_LIST = gql`
    query userList($user_id: Int){
        users(user_id: $user_id) {
            id
            first_name
            last_name
            username
            email
            active
            role_id
            client_id
            events: shows {
                id
                name
                primary_color
            }
        }
    }
`;

export default USER_LIST;
