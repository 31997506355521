import { Col, Form, Input, message, Popover, Row, Select, Switch } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleStatus, ModuleType } from '../../enums/module.enums';
import useAutoSavingForm from '../../hooks/useAutoSavingForm';
import useLogin from '../../hooks/useLogin';
import NavLinks from '../Module/Form/NavLinks';
import CustomizationCollapse from '../Module/Form/subforms/CustomizationCollapse';
import ModuleTypeForm from '../Module/Form/subforms/ModuleTypeForm';
import OptionsCollapse from '../Module/Form/subforms/OptionsCollapse';
import ProductLaunchForm from '../Module/Form/subforms/ProductLaunchForm';
import ReactionsCollapse from '../Module/Form/subforms/ReactionsCollapse';
import { Label, PageHeader } from './ModuleForm.styled';
import PageHeading from '../common/PageHeading';
import PageControls from '../common/PageControls';
import AutosaveStatus from '../AutosaveStatus/AutosaveStatus';
import { TooltipContentWrapper } from '../common/TooltipBubble/TooltipBubble';
import { useModuleFields } from './ModuleForm.hooks';
import MoodmeterOptionsCollapse from '../Module/Form/subforms/MoodmeterOptionsCollapse';
import usePresenterUrl from '../../hooks/presenter/usePresenterUrl';

const ModuleForm = (
  {
    type = 'create',
    form,
    onSubmit,
    module = {},
    setSubTypeChanged,
  },
) => {
  // we need user object here
  const { isModerator } = useLogin();
  const { t } = useTranslation();
  const { getFieldDecorator, validateFields, getFieldValue } = form;
  const { Option } = Select;
  const presenterUrl = usePresenterUrl();
  const [moodmeterLabels, setMoodmeterLabels] = useState(module.moodmeter_labels);
  const moduleFields = useModuleFields(getFieldValue('type'));

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    try {
      const payload = await validateFields();

      if (payload.show_new_comments !== false) {
        payload.show_new_comments = true;
      }

      if (payload.type === ModuleType.Reaction || payload.type === ModuleType.ProductLaunch) {
        payload.show_results = true;
        payload.show_total_count = true;
        payload.price = 0;
        payload.megavote_price = 0;
        payload.megavote_amount = 0;
      }
      // if type is not 'vote', module cannot be paid, otherwise user can choose
      payload.is_paid = payload.type === ModuleType.Vote ? Boolean(payload.is_paid) : false;
      payload.moodmeter_labels = moodmeterLabels;

      onSubmit(payload);
    } catch (exception) {
      message.error('Form submission failed. Check below for details');
    }
  };

  const [autosaveProps, triggerAutosave, autosaveStatus] = useAutoSavingForm(handleSubmit, {
    enabled: type === 'update',
    delay: 1000,
  });
  const moodmeterLabelsChanged = useMemo(() => {
    if (!module.moodmeter_labels) return false;
    if (!moodmeterLabels) return false;
    if (module.moodmeter_labels.length !== moodmeterLabels.length) return true;
    return !module.moodmeter_labels.every(
      (label, index) => moodmeterLabels[index] === label,
    );
  }, [module, moodmeterLabels]);

  useEffect(() => {
    if (!moodmeterLabelsChanged) return;
    triggerAutosave();
  }, [triggerAutosave, moodmeterLabelsChanged]);

  return (
    <div>
      <PageHeader>
        <PageHeading
          title="module"
          type={type}
          name={module.name}
        />
        <AutosaveStatus status={autosaveStatus} />
        <PageControls
          type={type}
          onClickCreate={handleSubmit}
          localizedButtons="modules.buttons"
          presenterUrl={presenterUrl}
        >
          {type === 'update' && (
            <div style={{ marginLeft: 12 }}>
              <NavLinks module={module} />
            </div>
          )}
        </PageControls>
      </PageHeader>
      {!isModerator && (
        <Form onSubmit={handleSubmit} className="module-form" {...autosaveProps}>
          <Row type="flex" gutter={16} justify="space-between">
            <Col md={24} xs={24}>
              <Col xs={12}>
                <ModuleTypeForm
                  form={form}
                  module={module}
                  setSubTypeChanged={setSubTypeChanged}
                  onChange={triggerAutosave}
                />
                {moduleFields.title && (
                  <Form.Item label={t('modules.form.title.label')}>
                    {getFieldDecorator('title', {
                      initialValue: module.title,
                      onChange: triggerAutosave,
                    })(<Input placeholder={t('modules.form.title.placeholder')} />)}
                  </Form.Item>
                )}
                {moduleFields.subtitle && (
                  <Form.Item label={t('modules.form.subtitle.label')}>
                    {getFieldDecorator('subtitle', {
                      initialValue: module.subtitle,
                      onChange: triggerAutosave,
                    })(<Input placeholder={t('modules.form.subtitle.placeholder')} />)}
                  </Form.Item>
                )}
                <CustomizationCollapse
                  form={form}
                  module={module}
                  onChange={triggerAutosave}
                />
                <ReactionsCollapse
                  module={module}
                  form={form}
                  onChange={triggerAutosave}
                />
              </Col>
              <Col lg={12} xl={8}>
                <ProductLaunchForm
                  form={form}
                  module={module}
                  onChange={triggerAutosave}
                />
                <OptionsCollapse
                  form={form}
                  module={module}
                  onChange={triggerAutosave}
                />
                <MoodmeterOptionsCollapse
                  form={form}
                  initialValue={moodmeterLabels}
                  onChange={setMoodmeterLabels}
                />
              </Col>
            </Col>
          </Row>
          <Row type="flex" gutter={16} justify="start">
            <Col span={16}>
              <Row type="flex" gutter={16}>
                <Col span={12}>
                  <Form.Item>
                    <Popover
                      content={(
                        <TooltipContentWrapper>
                          {t('modules.form.status.tooltip')}
                        </TooltipContentWrapper>
                      )}
                      placement="topLeft"
                      trigger="hover"
                    >
                      {getFieldDecorator('status', {
                        initialValue: module.status || ModuleStatus.Live,
                        rules: [
                          {
                            type: 'enum',
                            enum: [
                              ModuleStatus.NotStarted,
                              ModuleStatus.Live,
                              ModuleStatus.Ended,
                            ],
                          },
                        ],
                        onChange: triggerAutosave,
                      })(
                        <Select>
                          {[
                            ModuleStatus.NotStarted,
                            ModuleStatus.Live,
                            ModuleStatus.Ended,
                          ].map((status) => (
                            <Option key={status} value={status}>
                              {t(`modules.form.status.values.${status}`)}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </Popover>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    {getFieldDecorator('published', {
                      valuePropName: 'checked',
                      initialValue: module.published || false,
                      onChange: triggerAutosave,
                    })(
                      <Switch />,
                    )}
                    <Label>{t('modules.form.published.label')}</Label>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default Form.create()(ModuleForm);
