import { useMutation } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import CREATE_SESSION from '../../graphql/mutations/createSession';
import BreadCrumbs from '../../components/Breadcrumbs';

import SessionForm from '../../components/SessionForm/SessionForm';
import { useMappedParams } from '../../hooks/utils';
import { EventProvider } from '../../providers/EventProvider';
import { useEvent } from '../../hooks/providers/events';

function CreateSessionPage() {
  const { eventId } = useMappedParams({
    eventId: (params) => +params.event_id,
  });

  if (!eventId) return <CreateSessionWrapper />;

  return (
    <EventProvider eventId={eventId} refetch={false}>
      <CreateSessionWrapper />
    </EventProvider>
  );
}

const CreateSessionWrapper = () => {
  // local state variables
  const [redirect, setRedirect] = useState('');
  const [event] = useEvent();
  const [createSession] = useMutation(CREATE_SESSION);
  const [breadcrumbs, setBreadcrumbs] = useState([{
    breadcrumbName: 'Create new session',
    path: false,
  }]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    if (!event) return;
    setBreadcrumbs((bcs) => (
      [
        {
          breadcrumbName: event.name,
          path: `/events/${event.id}/edit`,
        },
        ...bcs,
      ]
    ));
  }, [event, setBreadcrumbs]);
  const onSubmit = async (payload) => {
    try {
      const result = await createSession({
        variables: { payload },
      });

      if (result) {
        const { session } = result.data;
        setRedirect(`/events/${session.event.id}/sessions/${session.id}`);
      }
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        setApiError(err.graphQLErrors[0].message);
      } else {
        setApiError('Unknown error occurred');
      }
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { referrer: 'successful-create' },
        }}
      />
    );
  }

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      <SessionForm
        type="create"
        error={apiError}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default CreateSessionPage;
