import { createGlobalStyle } from 'styled-components';

const GlobalStylesPresenter = createGlobalStyle`
  .app-content {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.3s linear;

    h1, h2, h3, h4, h5, h6, p {
      color: ${({ theme }) => theme.text};
    }

    h1 {
      font-size: 28px;
      line-height: 32px;
      font-weight: 700;

      @media (min-width: 1024px) {
        font-size: 32px;
        line-height: 36px;
      }

      @media (min-width: 1440px) {
        font-size: 42px;
        line-height: 40px;
      }
    }

    h2 {
      font-size: 22px;
      line-height: 24px;
      font-weight: 400;

      @media (min-width: 1024px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media (min-width: 1440px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
`;

export default GlobalStylesPresenter;
