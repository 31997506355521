import { useContext } from 'react';
import { RootContext } from '../context/root-context';

const useMenuCollapse = () => {
  const [state, setState] = useContext(RootContext);
  const setCollapsed = (menuCollapsed) => {
    setState((currentState) => ({
      ...currentState,
      menuCollapsed,
    }));
    if (menuCollapsed === true) {
      localStorage.setItem('menu_collapsed', 'true');
    } else {
      localStorage.removeItem('menu_collapsed');
    }
  };
  const isCollapsed = () => localStorage.getItem('menu_collapsed');
  return {
    menuCollapsed: state.menuCollapsed,
    setCollapsed,
    isCollapsed,
  };
};

export default useMenuCollapse;
