import { gql } from 'apollo-boost';

const CREATE_EVENT = gql`
    mutation createEvent($payload: ShowCreateInput!){
        event: createShow(payload: $payload) {
            id
        }
    }
`;

export default CREATE_EVENT;
