import React from 'react';
import { Button, Popover } from 'antd';

function TooltipButton({ children, buttonProps, tooltip }) {
  return (
    <Popover
      content={tooltip}
      placement="bottomLeft"
      trigger="hover"
    >
      <Button {...buttonProps}>
        {children}
      </Button>
    </Popover>
  );
}

export default TooltipButton;
