import { gql } from 'apollo-boost';

const CLIENT_LIST = gql`
    query clientList{
        clients {
            id
            company_name
            address
            registration
            vat
            website
            active
        }
    }
`;

export default CLIENT_LIST;
