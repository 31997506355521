import { gql } from 'apollo-boost';

const LIST_EVENTS = gql`
    query events($client_id: Int!){
        events: shows(client_id: $client_id) {
            id
            name
            domain_name
            description
        }
    }
`;

export default LIST_EVENTS;
