import { gql } from 'apollo-boost';

const MODERATED_COMMENT_ADD = gql`
    mutation moderatedCommentCreate($payload: ModeratedCommentCreateInput!){
        createModeratedComment(payload: $payload) {
            id
        }
    }
`;

export default MODERATED_COMMENT_ADD;
