import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { css } from 'styled-components';
import { Button as AntButton, Icon, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import ImageCropDialog from '../ImageCropDialog/ImageCropDialog';
import constants from '../../constants';
import { RATIO_SQUARE } from './ImageUploadRatio';

const containerSize = ({ ratio = RATIO_SQUARE }) => {
  const [wRatio, hRatio] = ratio;
  if (Number.isNaN(+wRatio) || Number.isNaN(+hRatio)) {
    return css`
      width: 100px;
      height: 100px
    `;
  }

  const height = 100;
  const width = Math.floor(height * (wRatio / hRatio));

  return css`
    width: ${width}px;
    height: ${height}px
  `;
};
const Container = styled.div`
  ${containerSize};
  border-radius: 5px;
  border: 1px solid #cccccc;
  background-color: rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const UploadButton = styled(AntButton)`
  width: 100%;
  height: 100%;
  font-size: 1rem;
`;
const ImageControls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const ImageButton = styled(AntButton)`
  padding: 0 8px;
  margin-left: 4px;
`;

const useImageCropper = (onComplete = () => null, {
  title = 'Upload',
  information = undefined,
  ratio,
} = {}) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();

  const upload = useCallback((file, crop) => {
    const crop_x = crop.x ?? 0;
    const crop_y = crop.y ?? 0;

    const body = new FormData();
    body.append('file', file);
    body.append('cropX', Math.round(crop_x));
    body.append('cropY', Math.round(crop_y));
    body.append('cropWidth', Math.round(crop.width));
    body.append('cropHeight', Math.round(crop.height));
    const options = { method: 'POST', body };
    return fetch(process.env.REACT_APP_UPLOAD_URL, options)
      .then((res) => res.json())
      .then((res) => res.filename);
  });

  const handleComplete = useCallback((filename) => {
    setVisible(false);
    onComplete(filename);
  }, [setVisible, onComplete]);

  const props = useMemo(() => ({
    title: t(title),
    information,
    visible,
    ratio,
    upload,
    onComplete: handleComplete,
  }), [visible, ratio, title, upload, handleComplete]);

  return {
    Component: ImageCropDialog,
    props,
    setVisible,
  };
};

const ImageUploadButton = (
  {
    value = null,
    onChange = () => null,
    title = 'Upload event image',
    information = undefined,
    ratio,
    previewRatio = ratio || RATIO_SQUARE,
  },
) => {
  const [currentValue, setCurrentValue] = useState(value);
  const handleUpload = useCallback((filename) => {
    if (!filename) return;

    setCurrentValue(filename);
    onChange(filename);
  }, [setCurrentValue, onChange]);
  const {
    Component: Cropper,
    props: cropperProps,
    setVisible: setCropperVisible,
  } = useImageCropper(handleUpload, { title, information, ratio });
  const showCropper = useCallback(
    () => setCropperVisible(true),
    [setCropperVisible],
  );

  const imageUrl = useMemo(() => {
    if (!currentValue) return undefined;
    return `${constants.uploadDir}/${currentValue}`;
  }, [currentValue]);

  const handleRemoveImage = useCallback(() => {
    if (!currentValue) return;
    setCurrentValue(null);
    onChange(null);
  }, [currentValue, setCurrentValue, onChange]);

  if (imageUrl) {
    return (
      <>
        <Cropper {...cropperProps} />
        <Container ratio={previewRatio}>
          <Image alt="" src={imageUrl} />
          <ImageControls>
            <Popconfirm
              placement="top"
              title="Are you sure you want to remove the image?"
              onConfirm={handleRemoveImage}
            >
              <ImageButton type="danger">
                <Icon type="delete" />
              </ImageButton>
            </Popconfirm>
          </ImageControls>
        </Container>
      </>
    );
  }

  return (
    <>
      <Cropper {...cropperProps} />
      <Container ratio={previewRatio}>
        <UploadButton size="small" onClick={showCropper}>
          <Icon type="file-image" />
        </UploadButton>
      </Container>
    </>
  );
};

export default ImageUploadButton;
