export default {};

const defaults_findPrefixed = {
  prefix(original, prefix) {
    return `${prefix}${original[0].toUpperCase()}${original.substr(1)}`;
  },
  match(value, key, source) {
    return key in source;
  },
  get(key, source) {
    return source[key];
  },
};

/**
 * @param {*} source
 * @param {string} original
 * @param {string[]} prefixes
 * @param {{
 *   prefix: undefined | function(
 *      original: string,
 *      prefix: string
 *   ): string,
 *   match: undefined | function(
 *      value: *,
 *      key: string,
 *      source: *,
 *      original: string|undefined,
 *      prefix: string|undefined
 *   ): boolean,
 *   get: undefined | function(
 *      key: string,
 *      source: *,
 *      original: string|undefined,
 *      prefix: string|undefined
 *   ): *,
 * }} options
 * @return {undefined|*}
 */
function findPrefixed(source, original, prefixes = [], options = defaults_findPrefixed) {
  const { prefix, match, get } = { ...defaults_findPrefixed, ...options };

  const value = get(original, source, original, undefined);
  if (match(value, original, source, original, undefined)) {
    return value;
  }

  for (let i = 0; i < prefixes.length; i++) {
    const key = prefix(original, prefixes[i]);
    const prefixValue = get(key, source, original, prefixes[i]);
    if (match(prefixValue, key, source, original, prefixes[i])) {
      return prefixValue;
    }
  }

  return undefined;
}

export const requestFullScreen = (element) => findPrefixed(
  element,
  'requestFullscreen',
  ['webkit'],
  {
    match: (value, key, source) => typeof source[key] === 'function',
  },
).call(element);

export const getFullScreenElement = () => findPrefixed(
  document,
  'fullscreenElement',
  ['webkit'],
);

export const exitFullscreen = () => findPrefixed(
  document,
  'exitFullscreen',
  ['webkit'],
  {
    match: (value, key, source) => typeof source[key] === 'function',
  },
).call(document);

/**
 * @param {function(Event): void} handler
 * @return {function(): void}
 */
export const onFullscreenChange = (handler) => {
  const key = findPrefixed(
    document,
    'fullscreenchange',
    ['webkit'],
    {
      prefix: (original, prefix) => `${prefix}${original}`,
      match: (value, eventName, source) => `on${eventName}` in source,
      get: (eventName) => eventName,
    },
  );
  document.addEventListener(key, handler);
  return () => {
    document.removeEventListener(key, handler);
  };
};
