import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import AnimatedReaction from './AnimatedReaction';
import Animations from './animations';
import useLoopingAnimationState from '../../../../hooks/animation/useLoopingAnimationState';

const ReactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  flex-grow: 1;
  flex-basis: 0;
  overflow: visible;
`;

const ReactionText = styled.h4`
  font-size: 24px;
  font-weight: normal;
  margin: 24px 0 24px;
`;

const ReactionResult = ({ count, image }) => {
  const { animation, height, offset } = Animations.animations[image] ?? {};
  const [state, startAnimation] = useLoopingAnimationState({
    duration: animation?.duration * animation?.iterations,
    delay: 30 + Math.floor(Math.random() * 60 * 1000),
    enabled: !!animation,
  });

  useEffect(() => {
    startAnimation();
  }, [count, startAnimation]);

  return (
    <ReactionContainer>
      <AnimatedReaction
        running={state === 'RUNNING'}
        image={image}
        height={height}
        offset={offset}
      />
      <ReactionText>{count}</ReactionText>
    </ReactionContainer>
  );
};

export default ReactionResult;
