import { Col, Form, Row, Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleType, ModuleTypeNames } from '../../../../enums/module.enums';
import { useModuleFields } from '../../../ModuleForm/ModuleForm.hooks';

const ModuleTypes = [
  {
    displayName: ModuleTypeNames.Vote,
    type: ModuleType.Vote,
  },
  {
    displayName: ModuleTypeNames.Comments,
    type: ModuleType.Comments,
  },
  {
    displayName: ModuleTypeNames.QuestionsAndAnswers,
    type: ModuleType.QuestionsAndAnswers,
  },
  {
    displayName: ModuleTypeNames.Reaction,
    type: ModuleType.Reaction,
  },
  {
    displayName: ModuleTypeNames.WordCloud,
    type: ModuleType.WordCloud,
  },
  {
    displayName: ModuleTypeNames.Moodmeter,
    type: ModuleType.Moodmeter,
  },
  {
    displayName: ModuleTypeNames.ProductLaunch,
    type: ModuleType.ProductLaunch,
  },
];
const ModuleTypeForm = ({ form, module, setSubTypeChanged, onChange = () => {} }) => {
  const { t } = useTranslation();
  const { getFieldValue, getFieldDecorator } = form;
  const { subType } = useMemo(() => ({
    type: getFieldValue('type'),
    subType: getFieldValue('subtype'),
  }), [form]);

  const moduleFields = useModuleFields(
    getFieldValue('type'),
  );

  const handleChangeSubType = useCallback((value) => {
    if (!setSubTypeChanged) return;

    setSubTypeChanged(value !== subType);
    onChange();
  }, [subType, setSubTypeChanged]);

  return (
    <Row type="flex" gutter={16}>
      <Col span={moduleFields.subtype ? 12 : 24}>
        <Form.Item label={t('modules.form.type.label')}>
          {getFieldDecorator('type', {
            rules: [{ required: true, message: t('modules.form.type.errors.required') }],
            initialValue: module.type || ModuleType.Vote,
            onChange,
          })(
            <Select>
              {ModuleTypes.map(({ type, displayName }) => (
                <Select.Option key={type} value={type}>
                  {displayName}
                </Select.Option>
              ))}
            </Select>,
          )}
        </Form.Item>
      </Col>
      {moduleFields.subtype && (
        <Col span={12}>
          <Form.Item label={t('modules.form.subtype.label')}>
            {getFieldDecorator('subtype', {
              rules: [{ required: true, message: t('modules.form.subtype.errors.required') }],
              initialValue: module.subtype || 'text',
              onChange: handleChangeSubType,
            })(
              <Select>
                {['text', 'photo'].map((subtype) => (
                  <Select.Option key={subtype} value={subtype}>
                    {t(`modules.form.subtype.values.${subtype}`)}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default ModuleTypeForm;
