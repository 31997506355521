import { gql } from 'apollo-boost';

const DUPLICATE_SESSION = gql`
    mutation duplicateSession($payload: EpisodeDuplicateInput!){
        session: duplicateEpisode(payload: $payload) {
            id
        }
    }
`;

export default DUPLICATE_SESSION;
