import { gql } from 'apollo-boost';

const UPDATE_MODULE = gql`
    mutation updateModule($module_id: Int!, $payload: ModuleUpdateInput!){
        module: updateModule(module_id: $module_id, payload: $payload) {
            id
            title
            episode_id
        }
    }
`;

export default UPDATE_MODULE;
