import React, { useCallback, useRef } from 'react';
import { Button, Icon, Input } from 'antd';

const FilterIcon = (props) => {
  const { label = 'Search' } = props;
  return (
    <div
      style={{
        color: 'blue',
        width: 'auto',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '1em',
      }}
      {...props}
    >
      <Icon type="search" />
      {label && <span style={{ fontWeight: 300 }}>&nbsp;{label}</span>}
    </div>
  );
};

const useFilterDropdown = () => {
  const ref = useRef();

  const filterDropdown = useCallback(({ selectedKeys, setSelectedKeys, confirm: confirmSearch, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={ref}
        placeholder="Search"
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirmSearch()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => confirmSearch()}
        icon="search"
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ), []);

  return { ref, filterDropdown };
};

const useFilterDropdownVisibility = (inputRef) => {
  const onFilterDropdownVisibleChange = useCallback((visible) => {
    if (!visible) return;

    setTimeout(() => {
      if (!inputRef.current) return;

      inputRef.current.select();
    });
  }, []);

  return { onFilterDropdownVisibleChange };
};

const useTableFilter = (dataIndex, label = 'Search') => {
  const { ref, filterDropdown } = useFilterDropdown();
  const { onFilterDropdownVisibleChange } = useFilterDropdownVisibility(ref);

  return {
    filterDropdown,
    onFilterDropdownVisibleChange,
    filterIcon: () => <FilterIcon label={label} />,
    onFilter: (value, record) => record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  };
};

export default useTableFilter;
