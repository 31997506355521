import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

export const VoteResultContainer = styled.div`
  width: 100%;
`;

export const VoteResultDetails = styled.div`
  display: flex;
  align-items: end;
  gap: 6px;
  margin-bottom: 6px;

  @media (min-width: 1024px) {
    gap: 10px;
    margin-bottom: 10px;
  }
`;

export const VoteResultHeading = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const VoteImage = styled.img`
  --width: 38px;
  width: var(--width);
  /* height: calc(calc(var(--width) / 12) * 9); */
  object-fit: contain;

  @media (min-width: 1024px) {
    --width: 56px;
  }

  @media (min-width: 1440px) {
    --width: 74px;
  }
`;

export const VoteResultTitle = styled.strong`
  font-size: 16px;
  line-height: 20px;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

export const VoteResultSubtitle = styled.span`
  font-size: 12px;
  line-height: 16px;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: 1440px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const VoteResultPercentage = styled.span`
  font-size: 16px;
  line-height: 20px;
  text-align: end;

  @media (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 40px;
  }
`;

const VoteResultProgress = styled.div`
  position: relative;
  border-radius: 12px;
  background-color: lightgray;
  width: 100%;
`;

const VoteResultProgressBar = styled.div`
  width: 100%;
  background-color: var(--color-primary, black);
  height: 18px;
  border-radius: 12px;
  transition: width 1s ease;
  transition-delay: 300ms;

  @media (min-width: 1024px) {
    height: 22px;
  }

  @media (min-width: 1440px) {
    height: 26px;
  }
`;

const VoteResult = ({ image, title, subtitle, count, total, color }) => {
  const [percentage, setPercentage] = useState(0);
  const [progress, setProgress] = useState('0%');

  useEffect(() => {
    if (!Number.isNaN(count) && !Number.isNaN(total) && total > 0) {
      setPercentage(
        Math.round((count / total) * 100),
      );
    }
  }, [count, total]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(`${percentage}%`);
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [percentage]);

  return (
    <VoteResultContainer>
      <VoteResultDetails>
        {image && (
          <VoteImage src={image} />
        )}
        <VoteResultHeading>
          <VoteResultTitle>{title}</VoteResultTitle>
          {subtitle && (
            <VoteResultSubtitle>{subtitle}</VoteResultSubtitle>
          )}
        </VoteResultHeading>
        {percentage >= 0 && (
          <VoteResultPercentage>{percentage}%</VoteResultPercentage>
        )}
      </VoteResultDetails>
      <VoteResultProgress>
        <VoteResultProgressBar
          style={{
            '--color-primary': color,
            width: progress,
          }}
        />
      </VoteResultProgress>
    </VoteResultContainer>
  );
};

export default VoteResult;
