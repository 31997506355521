import { gql } from 'apollo-boost';

const MODULE_LIST = gql`
    query modulesBySession($session_id: Int!){
        modules: modulesByEpisode(episode_id: $session_id) {
            id
            title
            subtitle
            subtitle_not_started
            subtitle_ended
            type
            subtype
            weight
            published
            status
        }
    }
`;

export default MODULE_LIST;
