import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

const SSE_URL = process.env.REACT_APP_SSE_URL;
const sse = (path) => `${SSE_URL}/${path}`;

const debug = {
  numSources: 0,
  sourceCreated() {
    this.numSources++;
  },
  sourceClosed() {
    this.numSources--;
  },
};

const useEventSource = (url, onData, extraDependencies = []) => {
  const [retry, setRetry] = useState(0);
  useEffect(() => {
    try {
      debug.sourceCreated();
      const source = new EventSource(url);
      source.addEventListener('update', onData);
      source.addEventListener('error', () => {
        setRetry((r) => r + 1);
      });

      return () => {
        source.close();
        debug.sourceClosed();
      };
    } catch (error) {
      setRetry((r) => r + 1);
      return () => {};
    }
  }, [url, onData, retry, setRetry, ...extraDependencies]);
};
const useEventSourceQuery = (url, query, variables) => {
  const [refetch, {
    loading: queryLoading,
    data: queryData,
    called: queryCalled,
    error: queryError,
  }] = useLazyQuery(query, { fetchPolicy: 'no-cache' });
  const loadData = useCallback(() => refetch({ variables }));

  const [data, setData] = useState(undefined);
  const loading = useMemo(() => {
    if (data) return false;
    if (!queryCalled) return true;
    return queryLoading;
  }, [data, queryCalled, queryLoading]);

  useEffect(() => {
    if (queryLoading) return;
    if (!queryCalled) return;
    if (!queryData) {
      setData(null);
      return;
    }

    setData(queryData);
  }, [queryLoading, queryCalled, queryData, setData]);

  useEventSource(url, loadData);
  useEffect(() => {
    refetch({ variables });
  }, [refetch, variables]);

  return [loadData, {
    called: queryCalled,
    error: queryError,
    loading,
    data,
  }];
};

const useEventChanges = (eventId, onData, extraDependencies = []) => {
  useEventSource(
    sse(`events/${eventId}/changes`),
    onData,
    extraDependencies,
  );
};
const useSessionChanges = (sessionId, onData, extraDependencies = []) => {
  useEventSource(
    sse(`sessions/${sessionId}/changes`),
    onData,
    extraDependencies,
  );
};
const useModuleChanges = (moduleId, onData, extraDependencies = []) => {
  useEventSource(
    sse(`modules/${moduleId}/changes`),
    onData,
    extraDependencies,
  );
};
const useModuleUpdates = (moduleId, onData, extraDependencies = []) => {
  useEventSource(
    sse(`modules/${moduleId}`),
    onData,
    extraDependencies,
  );
};

const useEventChangesQuery = (eventId, query, variables) => {
  const [refetch, { data, ...rest }] = useEventSourceQuery(
    sse(`events/${eventId}/changes`),
    query,
    variables,
  );

  return [data, { ...rest, refetch }];
};
const useSessionChangesQuery = (sessionId, query, variables) => {
  const [refetch, { data, ...rest }] = useEventSourceQuery(
    sse(`sessions/${sessionId}/changes`),
    query,
    variables,
  );

  return [data, { ...rest, refetch }];
};
const useModuleChangesQuery = (moduleId, query, variables) => {
  const [refetch, { data, ...rest }] = useEventSourceQuery(
    sse(`modules/${moduleId}/changes`),
    query,
    variables,
  );

  return [data, { ...rest, refetch }];
};
const useModuleUpdateQuery = (moduleId, query, variables) => {
  const [refetch, { data, ...rest }] = useEventSourceQuery(
    sse(`modules/${moduleId}`),
    query,
    variables,
  );

  return [data, { ...rest, refetch }];
};

export {
  useEventSource, useEventSourceQuery,
  useEventChanges, useEventChangesQuery,
  useSessionChanges, useSessionChangesQuery,
  useModuleChanges, useModuleChangesQuery,
  useModuleUpdates, useModuleUpdateQuery,
};
