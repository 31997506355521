import React, { createContext, useContext, useState } from 'react';

const ColorThemeContext = createContext({});

export const useColorThemeContext = () => useContext(ColorThemeContext);

export function ColorThemeProvider({ children }) {
  const [isLightTheme, setIsLightTheme] = useState(() => {
    const chosenTheme = localStorage.getItem('isLightTheme');
    return chosenTheme === 'true';
  });

  const handleThemeSwitch = () => {
    setIsLightTheme(!isLightTheme);
    localStorage.setItem('isLightTheme', !isLightTheme);
  };

  return <ColorThemeContext.Provider value={{ isLightTheme, handleThemeSwitch }} children={children} />;
}
