import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import QRCode from './QRCode';
import colors from '../../helpers/colorUtils';
import { useEvent, useModule } from '../../hooks/providers';
import useMediaQuery from '../../hooks/useMediaQuery';

const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`;

const QRMessage = styled.span`
  margin-top: 10px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;

  :last-of-type {
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    margin-top: 16px;
    font-size: 14px;
    line-height: 19px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const fullUrl = (domain_name, module_id) => {
  const proto = window.location.protocol;
  return `${proto}//${domain_name}.votemo.eu/${module_id}`;
};

const PresenterQR = () => {
  const isTablet = useMediaQuery('(max-width: 1023px)');
  const isLaptop = useMediaQuery('(max-width: 1339px) and (min-width: 1024px)');
  const [event] = useEvent();
  const [module] = useModule();

  const qrCodeSize = () => {
    if (isTablet) {
      return { width: 108, height: 128 };
    }

    if (isLaptop) {
      return { width: 148, height: 174 };
    }

    return { width: 228, height: 228 };
  };

  const eventUrl = useMemo(() => {
    if (!event) return null;
    return `${event.domain_name}.votemo.eu`;
  }, [event]);

  return (
    <>
      <QRContainer color={colors.hexToRGB(event.secondary_color)}>
        <QRCode
          data={fullUrl(event.domain_name, module.id)}
          options={{ margin: 0, ...qrCodeSize() }}
        />
        <QRMessage>
          Or go to
        </QRMessage>
        <QRMessage>
          {eventUrl}
        </QRMessage>
      </QRContainer>
    </>
  );
};

export default PresenterQR;
