import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Button, Switch, message, Icon, Table, Tag } from 'antd';
import { NavLink, Redirect } from 'react-router-dom';
import USER_LIST from '../../../graphql/queries/userList';
import USER_DELETE from '../../../graphql/mutations/userDelete';
import USER_UPDATE from '../../../graphql/mutations/userUpdate';

const UserList = () => {
  // local state variables
  const [editUserId, setEditUserId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  // graphql
  const { loading, error, data } = useQuery(USER_LIST, { fetchPolicy: 'no-cache' });
  const [updateUser] = useMutation(USER_UPDATE);
  const [deleteUser] = useMutation(USER_DELETE);

  const handleActiveClick = (event) => {
    event.stopPropagation();
  };
  const handleActiveChange = async (record) => {
    try {
      const result = await updateUser({
        variables: {
          user_id: record.id,
          payload: { active: !record.active },
        },
      });
      if (result) {
        message.success('Successfully updated user');
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };
  const onClickDelete = async (event, user_id) => {
    event.stopPropagation();
    try {
      const result = await deleteUser({
        variables: { user_id },
      });
      if (result) {
        message.success('Successfully deleted user');
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };

  // if an edit user id has been set, redirect to edit page
  if (editUserId) {
    return <Redirect push to={`/users/${editUserId}`} />;
  }

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  const structureTableData = () => {
    const tableData = data.users.map((user) => ({
      key: user.id,
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      active: user.active,
      events: user.events,
    }));
    const columns = [
      {
        title: 'First name',
        dataIndex: 'first_name',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      },
      {
        title: 'Last name',
        dataIndex: 'last_name',
        sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: 'Events',
        dataIndex: 'events',
        width: 400,
        render: (events) => (
          events.map((event) => (
            <Tag
              key={event.id}
              style={{ margin: 2, cursor: 'pointer' }}
              color={`#${event.primary_color}`}
            >
              {event.name}
            </Tag>
          ))
        ),
      },
      {
        title: 'Active',
        key: 'action_active',
        render: (text, record) => (
          <div
            tabIndex="0"
            role="button"
            onKeyPress={(event) => handleActiveClick(event)}
            onClick={(event) => handleActiveClick(event)}
          >
            <Switch
              onChange={() => handleActiveChange(record)}
              defaultChecked={record.active}
            />
          </div>
        ),
      },
      {
        title: '',
        key: 'action_delete',
        render: (text, record) => (
          <Button type="link" size="small" onClick={(event) => onClickDelete(event, record.id)}>
            <Icon type="delete" />
          </Button>
        ),
      },
    ];

    return {
      columns,
      tableData,
    };
  };
  const table = structureTableData();
  return (
    <div>
      <header className="page-header">
        <h1>Users</h1>
        <Button type="primary">
          <NavLink to="/users/create">Add new user</NavLink>
        </Button>
      </header>
      <Table
        columns={table.columns}
        dataSource={table.tableData}
        pagination={{
          current: currentPage,
          pageSize: currentPageSize,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
          onShowSizeChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
        }}
        onRow={(record) => ({
          onClick: () => {
            setEditUserId(record.id);
          },
        })}
      />
    </div>
  );
};

export default UserList;
