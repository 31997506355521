import styled from 'styled-components/macro';

export const commentMargin = ({ isReply }) => {
  if (isReply) return '100px';
  return 0;
};
const commentBackground = ({ isHidden }) => {
  if (isHidden) {
    return 'transparent';
  }
  return 'white';
};
const commentColor = ({ isHidden }) => {
  if (isHidden) {
    return '#333333';
  }

  return 'black';
};
export const Comment = styled.div`
  background-color: ${commentBackground};
  border: 1px solid white;
  margin-left: ${commentMargin};
  position: relative;
  border-radius: .5rem;
  margin-bottom: 1rem;
  padding: .5rem;
  color: ${commentColor};
  
  transition: all ease-in-out 300ms;
`;
export const CommentHeader = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;
const authorColor = ({ moderatorColor }) => moderatorColor || 'black';
export const CommentAuthor = styled.div`
  font-weight: bold;

  .author-name {
    color: ${authorColor};
  }

  .moderator-img {
    display: inline-block;
    margin-left: 0.5rem;
    width: 18px;
    height: 18px;

    img {
      width: 100%;
    }
  }
`;
const timestampColor = ({ isHidden }) => {
  if (isHidden) {
    return '#666666';
  }
  return '#bababb';
};
export const CommentTimestamp = styled.div`
  margin-left: 0.5rem;
  color: ${timestampColor};
  transition: all ease-in-out 300ms;

  &:before {
    font-weight: 900;
    font-size: 5px;
    content: "\f111";
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`;
export const CommentContent = styled.div`
  word-wrap: anywhere;
  overflow: hidden;
  flex-shrink: 1;
  margin-right: 100px;
`;
export const CommentControls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;
  color: blue;
  font-weight: bold;
  padding: .5em .5em .2em;

  .upvotes {
    font-size: .9em;
  }

  .upvotes span {
    margin: 0 .2em;
  }

  button {
    color: inherit;
    border: none;
    background-color: transparent;
    font-weight: inherit;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;
