import styled from 'styled-components/macro';

export default {};
export const ReplyContainer = styled.div`
  background-color: #ebebeb;
  transition: height 300ms ease-in-out;
  overflow: hidden;
  box-sizing: border-box;
  height: ${({ visible }) => (visible ? 100 : 0)}px;
`;
export const ReplyWrapper = styled.div`
  padding: 20px 16px 0;
  position: relative;
  
  .label__replying-to {
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 12px;
  }
`;
