import { useMemo } from 'react';

import { RouteBuilders } from '../../routes';

import { hasValidSession, isModuleValid, isSessionValid } from '../../providers/presenter/utils';
import { useEvent, useModule, useSession } from '../providers';

export default function usePresenterUrl() {
  const [event] = useEvent();
  const [session] = useSession();
  const [module] = useModule();

  const params = useMemo(() => {
    if (!event) return false;
    if (!hasValidSession(event)) return false;

    if (!session) return { eventId: event.id };
    if (!isSessionValid(session)) return false;

    if (!module) return { eventId: event.id, sessionId: session.id };
    if (!isModuleValid(module)) return false;

    return { eventId: event.id, sessionId: session.id, moduleId: module.id };
  }, [event, session, module]);

  return useMemo(() => {
    if (!params) return null;

    if (params.moduleId) return RouteBuilders.Presenter.ofModule(params);
    if (params.sessionId) return RouteBuilders.Presenter.ofSession(params);
    if (params.eventId) return RouteBuilders.Presenter.ofEvent(params);

    return null;
  });
}
