import { gql } from 'apollo-boost';

const CLIENT_ADD = gql`
    mutation clientCreate($payload: ClientCreateInput!){
        createClient(payload: $payload) {
            id
        }
    }
`;

export default CLIENT_ADD;
