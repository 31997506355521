import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { PresenterButtonIcon } from './PageControls.styled';

const SaveButton = ({ children, onClick }) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Button type="primary" onClick={onClick} style={{ marginRight: 12 }}>
      {children}
    </Button>
  </div>
);

const PresenterButton = ({ icon = 'desktop', iconColor, iconTheme, url, children }) => (
  <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 8 }}>
    <Button type="default" style={{ display: 'flex', alignItems: 'center' }} href={url} target="_blank">
      <PresenterButtonIcon type={icon} color={iconColor} theme={iconTheme} />
      {children}
    </Button>
  </div>
);

export default function PageControls(
  {
    type = 'create',
    presenterUrl = null,
    onClickCreate = () => {},
    localizedButtons = 'common.buttons',
    children,
  },
) {
  const { t } = useTranslation();
  const saveText = useMemo(
    () => t(`${localizedButtons}.save`) || t('common.buttons.save'),
    [t],
  );

  if (type === 'create') {
    return <SaveButton onClick={onClickCreate}>{saveText}</SaveButton>;
  }

  return (
    <>
      {presenterUrl && (
        <PresenterButton url={presenterUrl}>
          Present
        </PresenterButton>
      )}
      {children}
    </>
  );
}
