function buildRoute(route, params) {
  return Object.entries(params)
    .reduce(
      (result, [param, value]) => result.replaceAll(
        `:${param}`,
        encodeURIComponent(value),
      ),
      route,
    );
}

// TODO: Refactor snake case to camel case
export const Routes = {
  ofEvent: '/events/:event_id',
  ofSession: '/events/:event_id/sessions/:session_id',
  ofModule: '/events/:event_id/sessions/:session_id/modules/:module_id',
  Presenter: {
    ofEvent: '/presenter/:event_id',
    ofSession: '/presenter/:event_id/sessions/:session_id',
    ofModule: '/presenter/:event_id/sessions/:session_id/modules/:module_id',
  },
};
export const RouteBuilders = {
  ofEvent: ({ eventId }) => buildRoute(Routes.ofEvent, {
    event_id: eventId,
  }),
  ofSession: ({ eventId, sessionId }) => buildRoute(Routes.ofSession, {
    event_id: eventId,
    session_id: sessionId,
  }),
  ofModule: ({ eventId, sessionId, moduleId }) => buildRoute(Routes.ofModule, {
    event_id: eventId,
    session_id: sessionId,
    module_id: moduleId,
  }),
  Presenter: {
    ofEvent: ({ eventId }) => buildRoute(Routes.Presenter.ofEvent, {
      event_id: eventId,
    }),
    ofSession: ({ eventId, sessionId }) => buildRoute(Routes.Presenter.ofSession, {
      event_id: eventId,
      session_id: sessionId,
    }),
    ofModule: ({ eventId, sessionId, moduleId }) => buildRoute(Routes.Presenter.ofModule, {
      event_id: eventId,
      session_id: sessionId,
      module_id: moduleId,
    }),
  },
};
