import { gql } from 'apollo-boost';

const DUPLICATE_EVENT = gql`
    mutation duplicateEvent($payload: EventDuplicateInput!){
        session: duplicateEvent(payload: $payload) {
            id,
            name,
            domain_name            
        }
    }
`;

export default DUPLICATE_EVENT;
