import React, { useContext, useEffect } from 'react';

import { useGetModule } from '../hooks/api/modules';

const ModuleContext = React.createContext({});

/**
 * @returns {{ module, loading, load }}
 */
export const useModuleContext = () => useContext(ModuleContext);

export function ModuleProvider({ children, moduleId, refetch = 5 * 1000 }) {
  const [module, { load, loading }] = useGetModule(moduleId);
  // FIXME
  // useModuleChanges(moduleId, () => load(moduleId), [moduleId]);

  useEffect(() => {
    if (!refetch) return undefined;
    if (!moduleId) return undefined;

    const interval = setInterval(load, refetch);
    return () => clearInterval(interval);
  }, [refetch, moduleId, load]);

  useEffect(() => load(moduleId), [moduleId]);

  return <ModuleContext.Provider value={{ module, loading, load }} children={children} />;
}
