import { gql } from 'apollo-boost';

const DELETE_MODULE = gql`
    mutation deleteModule($module_id: Int!){
        module: deleteModule(module_id: $module_id) {
            id
            title
        }
    }
`;

export default DELETE_MODULE;
