import { gql } from 'apollo-boost';

const USER_UPDATE = gql`
    mutation userUpdate($user_id: Int!, $payload: UserUpdateInput!){
        updateUser(user_id: $user_id, payload: $payload) {
            id
        }
    }
`;

export default USER_UPDATE;
