import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Animations from './animations';

const CReactionContainer = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
`;

const CReactionAnimationIcon = styled.div`
  display: block;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 0;
  transform: ${({ height }) => [
    'scale(1)',
    `translate(-50%, ${120 - height}px)`,
  ].join(' ')};
  ${({ offset }) => (offset ? `margin-left: ${offset.x || 0}px;` : '')}
  ${({ offset }) => (offset ? `margin-top: ${offset.y || 0}px;` : '')}

  ${({ image }) => Animations.style(image)}
`;

const CReactionOverlay = styled.div`
  display: block;
  width: 80px;
  height: 80px;
  margin: auto auto;
  background-image: url(${({ idleImage }) => idleImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .25;
`;

// TODO: Add support for different idle image with proper scaling
// eslint-disable-next-line
const AnimatedReactionOverlay = ({ idleImage }) => {
  if (!idleImage) {
    return null;
  }

  // TODO: Remove false
  return false && (
    <CReactionOverlay idleImage={idleImage} />
  );
};

const AnimatedReaction = ({ image, running, height, offset }) => {
  const { idleImage } = Animations.animations[image];

  /** @type {React.MutableRefObject<HTMLElement>} */
  const iconRef = useRef();
  const [allowReflow, setAllowReflow] = useState(false);

  useEffect(() => {
    // skip the first time, since that would cause a flicker
    if (running === true && !allowReflow) {
      setAllowReflow(true);
      return;
    }

    if (running === true) {
      iconRef.current.style.animation = 'none';
      // this will force a reflow, re-starting the animation
      // https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
      // https://gist.github.com/paulirish/5d52fb081b3570c81e3a
      iconRef.current.getClientRects();
      iconRef.current.style.animation = '';
    }
  }, [running]);

  return (
    <CReactionContainer>
      <AnimatedReactionOverlay idleImage={idleImage} />
      <CReactionAnimationIcon
        ref={iconRef}
        height={height}
        offset={offset}
        image={image}
      />
    </CReactionContainer>
  );
};

export default AnimatedReaction;
