import { gql } from 'graphql-tag';

const LIST_CLIENT_EMOJIS = gql`
  query ($client_id: Int!) {
    clientEmojis(client_id: $client_id){
      id
      name
      emojiPackId
      available
    }
  }
`;
const LIST_CLIENT_EMOJI_PACKS = gql`
  query ($client_id: Int!) {
    clientEmojiPacks(client_id: $client_id){
      id
      name
      description
      available
      emojis {
        id
        name
        available
      }
    }
  }
`;
const UPDATE_CLIENT_EMOJI_PACKS = gql`
  mutation($payload: UpdateClientEmojiPacksInput!) {
    updateClientEmojiPacks(payload: $payload) {
      id
      name
      description
      available
      emojis {
        id
        name
        available
      }
    }
  }
`;

export {
  LIST_CLIENT_EMOJIS,
  LIST_CLIENT_EMOJI_PACKS,
  UPDATE_CLIENT_EMOJI_PACKS,
};
