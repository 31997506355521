import { gql } from 'apollo-boost';

const CREATE_SESSION = gql`
    mutation createSession($payload: EpisodeCreateInput!){
        session: createEpisode(payload: $payload) {
            id
            name
            event: show {
                id
            }
        }
    }
`;

export default CREATE_SESSION;
