import React from 'react';
import * as Styled from './AutosaveStatus.styled';
import LocalizedTooltipBubble from '../common/TooltipBubble/TooltipBubble';

function AutosaveStatus({ status }) {
  if (status === 'DISABLED') return null;
  if (status === 'SAVED') return <Styled.Saved>Saved!</Styled.Saved>;

  if (status === 'IDLE') {
    return (
      <Styled.Idle>
        <LocalizedTooltipBubble
          contentKey="common.autosave.tooltip"
          placement="bottomRight"
          color="blue"
        />
        <span style={{ marginLeft: 8 }}>Autosave Enabled</span>
      </Styled.Idle>
    );
  }

  return <Styled.Saving>Saving...</Styled.Saving>;
}

export default AutosaveStatus;
