import { gql } from 'apollo-boost';

const GET_PUBLIC_EVENT = gql`
    query publicEvent($event_id: Int!){
        event: publicShow(show_id: $event_id) {
            id
            name
            description
            session: episode {
                id
                name
                description
                modules {
                    id
                    title
                    subtitle
                }
            }
        }
    }
`;

export default GET_PUBLIC_EVENT;
