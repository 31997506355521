import styled from 'styled-components/macro';

export const PageHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;
export const BlockRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: right;
`;
export const CLinkButton = styled.button`
  background-color: transparent;
  color: #313A50;
  border: none;
  padding: 4px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 0 0 auto;
  display: flex;
`;
export const CLink = styled.a`
  background-color: transparent;
  color: #313A50;
  text-decoration: none;
  display: block;
  padding: 8px 4px 4px;
  font-weight: 700;
  font-size: .9em;
  cursor: pointer;
  text-align: left;
`;
