import React from 'react';

import VotePresenter from './presenters/VotePresenter/VotePresenter';
import CommentsPresenter from './presenters/CommentsPresenter/CommentsPresenter';
import ReactionPresenter from './presenters/ReactionPresenter/ReactionPresenter';
import WordCloudPresenter from './presenters/WordCloudPresenter/WordCloudPresenter';
import MoodMeterPresenter from './presenters/MoodMeterPresenter/MoodMeterPresenter';
import { ModuleType } from '../../enums/module.enums';
import { useModule } from '../../hooks/providers';

export default function PresenterContent() {
  const [module] = useModule();
  if (!module) return null;

  switch (module.type) {
    case ModuleType.Reaction:
      return (
        <ReactionPresenter module={module} />
      );
    case ModuleType.Vote:
      return (
        <VotePresenter module={module} />
      );
    case ModuleType.Comments:
    case ModuleType.QuestionsAndAnswers:
      return (
        <CommentsPresenter module={module} />
      );
    case ModuleType.WordCloud:
      return (
        <WordCloudPresenter module={module} />
      );
    case ModuleType.Moodmeter:
      return (
        <MoodMeterPresenter module={module} />
      );
    default:
      return null;
  }
}
