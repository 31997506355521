import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown, Icon, Menu } from 'antd';
import styled from 'styled-components/macro';
import { NavLink, useHistory } from 'react-router-dom';
import { RootContext } from '../../../context/root-context';
import {
  exitFullscreen,
  getFullScreenElement,
  onFullscreenChange,
  requestFullScreen,
} from '../../../helpers/fullscreen';
import { useAdjacentModules, useEvent, useModulePath, useSessionModules } from '../../../hooks/providers';
import { useEventColors } from '../../../hooks/useEventColors';
import { useColorThemeContext } from '../../../providers/ColorThemeProvider';
import { Moon, Sun } from '../../../assets/icons';

const useMenuStyle = () => {
  const colors = useEventColors();

  return useMemo(() => {
    if (!colors) {
      return {
        width: '300px',
        background: 'var(--primary-color, white)',
      };
    }

    return {
      '--primary-color': `#${colors.primary}`,
      '--secondary-color': `#${colors.secondary}`,
    };
  }, [colors]);
};

const CPresenterMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  gap: 16px;

  .divider {
    display: inline-block;
    width: 2px;
    background-color: #e0e0e0;
    margin: 0 1rem;
  }
`;
const CPresenterMenuButton = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  color: #a0a0a0;
  font-size: 24px;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;

const PresenterMenu = () => {
  const menuRef = useRef();

  const [event] = useEvent();
  const style = useMenuStyle();
  const [modules] = useSessionModules();
  const { handleThemeSwitch, isLightTheme } = useColorThemeContext();

  // TODO: Refactor this out
  const [, setState] = useContext(RootContext);
  const handleToggleQRCode = (e) => {
    e.preventDefault();
    setState((oldState) => {
      let display_qr = false;
      if (oldState.presenter) {
        if (typeof oldState.presenter.display_qr === 'boolean') {
          display_qr = !oldState.presenter.display_qr;
        }
      }
      const presenter = oldState.presenter
        ? { ...oldState.presenter, display_qr }
        : { display_qr };
      return { ...oldState, presenter };
    });
  };

  const menuStyle = {
    color: 'var(--secondary-color, black)',
  };
  const [isFullscreen, setIsFullscreen] = useState(false);
  useEffect(() => {
    const handler = () => {
      const fullscreenElement = getFullScreenElement();
      setIsFullscreen(!!fullscreenElement);
    };
    const removeListener = onFullscreenChange(handler);
    return () => {
      removeListener();
    };
  });

  const toggleFullWindow = () => {
    requestFullScreen(
      document.getElementById('app-content'),
    );
  };
  const toggleFullScreen = () => {
    if (getFullScreenElement()) {
      exitFullscreen();
    } else {
      requestFullScreen(
        document.documentElement,
      );
    }
  };

  const history = useHistory();
  const modulePath = useModulePath();
  const { previous, next } = useAdjacentModules();
  const handlePreviousModule = useCallback(() => {
    if (!previous) return;

    history.push(modulePath(previous));
  }, [history, previous, modulePath]);
  const handleNextModule = useCallback(() => {
    if (!next) return;

    history.push(modulePath(next));
  }, [history, next, modulePath]);

  if (!event) return null;

  return (
    <CPresenterMenu ref={menuRef}>
      <CPresenterMenuButton onClick={handlePreviousModule}>
        <Icon type="left" />
      </CPresenterMenuButton>
      <CPresenterMenuButton onClick={handleNextModule}>
        <Icon type="right" />
      </CPresenterMenuButton>
      <span className="divider" />
      {event.display_qr && (
        <CPresenterMenuButton type="button" title="Toggle the QR code" onClick={handleToggleQRCode}>
          <Icon type="qrcode" />
        </CPresenterMenuButton>
      )}
      <CPresenterMenuButton type="button" title="Toggle dark/light theme" onClick={handleThemeSwitch}>
        <Icon component={isLightTheme ? Moon : Sun} />
      </CPresenterMenuButton>
      <CPresenterMenuButton type="button" title="Toggle full-screen" onClick={toggleFullScreen}>
        <Icon type={isFullscreen ? 'fullscreen-exit' : 'fullscreen'} />
      </CPresenterMenuButton>
      {!isFullscreen && (
        <CPresenterMenuButton type="button" title="Open as full-window" onClick={toggleFullWindow}>
          <Icon type="desktop" />
        </CPresenterMenuButton>
      )}
      <Dropdown
        overlay={(
          <Menu style={style}>
            {modules.map((item) => (
              <Menu.Item key={item.id}>
                <NavLink key={item.id} to={modulePath(item)} style={menuStyle}>
                  {item.title}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        )}
        trigger={['click']}
        getPopupContainer={() => menuRef.current}
      >
        <CPresenterMenuButton type="button">
          <Icon type="menu" />
        </CPresenterMenuButton>
      </Dropdown>
    </CPresenterMenu>
  );
};

export default PresenterMenu;
