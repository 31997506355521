import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { NavLink, Redirect } from 'react-router-dom';
import { Button, Switch, message, Icon, Table } from 'antd';

import CLIENT_LIST from '../../graphql/queries/clientList';
import CLIENT_DELETE from '../../graphql/mutations/clientDelete';
import CLIENT_UPDATE from '../../graphql/mutations/clientUpdate';

const ClientsListPage = () => {
  // local state variables
  const [viewClientId, setViewClientId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  // graphql
  const { loading, error, data } = useQuery(CLIENT_LIST);
  const [updateClient] = useMutation(CLIENT_UPDATE, {
    refetchQueries: ['clientList'],
  });
  const [deleteClient] = useMutation(CLIENT_DELETE, {
    refetchQueries: ['clientList'],
  });

  const handleActiveClick = (event) => {
    event.stopPropagation();
  };
  const handleActiveChange = async (record) => {
    try {
      const result = await updateClient({
        variables: {
          client_id: record.id,
          payload: {
            active: !record.active,
          },
        },
      });
      if (result) {
        message.success('Successfully updated client');
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };
  const onClickDelete = async (event, client_id) => {
    event.stopPropagation();
    try {
      const result = await deleteClient({
        variables: {
          client_id,
        },
      });
      if (result) {
        message.success('Successfully deleted client');
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };

  const structureTableData = () => {
    const tableData = data.clients.map((client, index) => ({
      key: index + 1,
      id: client.id,
      name: client.company_name,
      website: client.website,
      active: client.active,
    }));

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Website',
        dataIndex: 'website',
        sorter: (a, b) => a.website.localeCompare(b.website),
      },
      {
        title: 'Active',
        key: 'action_active',
        render: (text, record) => (
          <div
            tabIndex="0"
            role="button"
            onKeyPress={(event) => handleActiveClick(event)}
            onClick={(event) => handleActiveClick(event)}
          >
            <Switch
              onChange={() => handleActiveChange(record)}
              defaultChecked={record.active}
            />
          </div>
        ),
      },
      {
        title: '',
        key: 'action_delete',
        render: (text, record) => (
          <Button type="link" size="small" onClick={(event) => onClickDelete(event, record.id)}>
            <Icon type="delete" />
          </Button>
        ),
      },
    ];
    return {
      tableData,
      columns,
    };
  };
  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  if (viewClientId) {
    return <Redirect push to={`/clients/${viewClientId}`} />;
  }

  const table = structureTableData();

  return (
    <div>
      <header className="page-header">
        <h1>Clients</h1>
        <Button type="primary">
          <NavLink to="/clients/create">Add new client</NavLink>
        </Button>
      </header>
      <Table
        columns={table.columns}
        dataSource={table.tableData}
        pagination={{
          current: currentPage,
          pageSize: currentPageSize,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
          onShowSizeChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
        }}
        onRow={(record) => ({
          onClick: () => {
            setViewClientId(record.id);
          },
        })}
      />
    </div>
  );
};

export default ClientsListPage;
