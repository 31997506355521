import React, { useRef, useState } from 'react';
import { Form, Row, Col, Input, Switch, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import EmojiPackFormFields from './emojiPacks';

const ClientForm = ({ form, onSubmit, client }) => {
  const emojiPacksRef = useRef();
  const submitRef = useRef();
  // generates a random API key
  const generateApiKey = () => {
    let result = '';
    const characters = 'ABCDEFGHKLMNPQRSTUVWXYZ23456789';
    for (let i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
  };

  const { getFieldDecorator, validateFields } = form;
  const { t } = useTranslation();
  const [api_key, setApiKey] = useState((client && client.api_key) || generateApiKey());
  const company_name = (client && client.company_name) || '';
  const vat = (client && client.vat) || '';
  const registration = (client && client.registration) || '';
  const website = (client && client.website) || '';
  const address = (client && client.address) || '';
  const active = client && typeof client.active !== 'undefined' ? client.active : true;
  const whitelabel = client && client.whitelabel === true;

  const buttonText = client && client.company_name
    ? t('client.form.button_update')
    : t('client.form.button_create');

  const onFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = await validateFields();
      const emojiPacks = emojiPacksRef.current;
      await onSubmit({ ...payload, emojiPacks });
      await submitRef.current();
    } catch (exception) {
      message.error('Unable to submit. Check below for details');
    }
  };
  const renderForm = () => (
    <Form onSubmit={onFormSubmit}>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="Company name">
            {getFieldDecorator('company_name', {
              rules: [{ required: true, message: 'Please input a company name!' }],
              initialValue: company_name,
            })(<Input placeholder="Company name" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="VAT">
            {getFieldDecorator('vat', {
              initialValue: vat,
            })(<Input placeholder="VAT" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="Registration">
            {getFieldDecorator('registration', {
              initialValue: registration,
            })(<Input placeholder="Registration" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="Website">
            {getFieldDecorator('website', {
              initialValue: website,
            })(<Input placeholder="Website" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="Address">
            {getFieldDecorator('address', {
              initialValue: address,
            })(<Input placeholder="Address" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="API Key">
            {getFieldDecorator('api_key', {
              rules: [{ required: true, message: 'Please input an API key!' }],
              initialValue: api_key,
            })(<Input placeholder="API Key" />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="Regenerate">
            <Button type="button" onClick={() => setApiKey(generateApiKey())}>Regenerate</Button>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Active">
            {getFieldDecorator('active', { valuePropName: 'checked', initialValue: active })(
              <Switch />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Whitelabel">
            {getFieldDecorator('whitelabel', {
              valuePropName: 'checked',
              initialValue: whitelabel,
            })(
              <Switch />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label="Available emoji packs">
            <EmojiPackFormFields
              client={client}
              valueRef={emojiPacksRef}
              submitRef={submitRef}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item>
            <Button block size="large" type="primary" htmlType="submit">
              {buttonText}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <div>
      {renderForm()}
    </div>
  );
};

export default Form.create()(ClientForm);
