import React from 'react';

import { ModuleProvider } from './ModuleProvider';
import { EventProvider } from './EventProvider';
import { SessionProvider } from './SessionProvider';
import { ColorThemeProvider } from './ColorThemeProvider';

import { usePresenterParams } from './hooks/usePresenterParams';
import PresenterValidator from './presenter/PresenterValidator';
import PresenterRedirect from './presenter/PresenterRedirect';

// eslint-disable-next-line import/prefer-default-export
export function PresenterProvider({ children }) {
  const { eventId, sessionId, moduleId } = usePresenterParams();
  const refetch = false;

  return (
    <ColorThemeProvider>
      <EventProvider eventId={eventId} refetch={refetch}>
        <SessionProvider sessionId={sessionId} refetch={refetch}>
          <ModuleProvider moduleId={moduleId} refetch={refetch}>
            <PresenterValidator>
              <PresenterRedirect children={children} />
            </PresenterValidator>
          </ModuleProvider>
        </SessionProvider>
      </EventProvider>
    </ColorThemeProvider>
  );
}
