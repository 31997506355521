import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useEventColors } from '../../hooks/useEventColors';

import PresenterSidebar from '../../components/Presenter/PresenterSidebar';
import PresenterHeader from '../../components/Presenter/PresenterHeader';
import PresenterFooter from '../../components/Presenter/PresenterFooter';
import PresenterContent from '../../components/Presenter/PresenterContent';

import { lightTheme, darkTheme } from './Themes';
import GlobalStylesPresenter from './globalStyles.presenter';
import { useColorThemeContext } from '../../providers/ColorThemeProvider';
import colorUtils from '../../helpers/colorUtils';

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  max-height: calc(100vh - 84px);
  margin-left: 16px;
  padding: 16px 16px 0 0;

  @media (min-width: 1024px) {
    margin-left: 24px;
    padding: 20px 24px 0 0;
  }

  @media (min-width: 1440px) {
    margin-left: 42px;
    padding: 32px 42px 0 0;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${colorUtils.colorProp('#dfdfdf', 'secondaryColor')};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${colorUtils.colorProp('#c0c0c0', 'primaryColor')};
  }
`;

export default function Presenter() {
  const colors = useEventColors();
  const { isLightTheme } = useColorThemeContext();

  return (
    <ThemeProvider theme={isLightTheme ? lightTheme : darkTheme}>
      <>
        <GlobalStylesPresenter />
        <div className="app app__presenter">
          <PresenterHeader />
          <div id="app-content" className="app-content">
            <PresenterSidebar />
            <ContentWrapper primaryColor={colors.primary} secondaryColor={colors.secondary}>
              <PresenterContent />
            </ContentWrapper>
            <PresenterFooter />
          </div>
        </div>
      </>
    </ThemeProvider>
  );
}
