import { useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';
import { CREATE_EVENT } from '../../graphql/events';

export default function useCreateEvent(client_id) {
  const [fetch] = useMutation(CREATE_EVENT);

  const createEvent = useCallback(
    (payload) => fetch({
      variables: {
        payload: { ...payload, client_id },
      },
    }),
    [fetch, client_id],
  );

  return [createEvent];
}
