// TODO: Refactor 'ProductLaunch' -> 'Banner'
export const ModuleTypeNames = {
  Comments: 'Chat',
  QuestionsAndAnswers: 'Q&A',
  Vote: 'Vote',
  Reaction: 'Reaction',
  WordCloud: 'Word Cloud',
  Moodmeter: 'Mood Meter',
  Banner: 'Banner',
  ProductLaunch: 'Banner',
};
// TODO: Refactor 'product_launch' -> 'banner'
export const ModuleType = {
  Comments: 'comment',
  QuestionsAndAnswers: 'questions_answers',
  Vote: 'vote',
  Reaction: 'reaction',
  WordCloud: 'word_cloud',
  Moodmeter: 'moodmeter',
  ProductLaunch: 'product_launch',
  Banner: 'product_launch',
};
export const ModulesWithPresenter = [
  ModuleType.Reaction,
  ModuleType.Vote,
  ModuleType.Comments,
  ModuleType.QuestionsAndAnswers,
  ModuleType.WordCloud,
  ModuleType.Moodmeter,
];
export function isModulePresentable(module) {
  return ModulesWithPresenter.some((type) => module.type === type);
}
export const ModuleStatus = {
  Live: 'LIVE',
  NotStarted: 'NOT_STARTED',
  Ended: 'ENDED',
};
