import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

import VOTE_RESULTS from '../../../../graphql/queries/voteResults';
import { useEventColors } from '../../../../hooks/useEventColors';

import { useModuleUpdateQuery } from '../../hooks/useModuleUpdates';
import WordCloudHeading from './WordCloudHeading';
import WordCloudResults from './WordCloudResults';
import './WordCloudPresenter.scss';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 80%;
`;

const WordCloudPresenter = ({ module }) => {
  const colors = useEventColors();
  const [data] = useModuleUpdateQuery(
    module.id, VOTE_RESULTS,
    useMemo(
      () => ({ module_id: module.id }),
      [module.id],
    ),
  );

  const votes = useMemo(() => {
    if (!data) return [];
    if (!data.voteResults) return [];
    if (!data.voteResults.options) return [];
    return data.voteResults.options;
  }, [data]);

  const cloud = useMemo(() => {
    if (!votes) return [];

    return votes.map((vote) => ({
      text: vote.option.title,
      value: vote.totalCount,
    }));
  }, [votes]);

  return (
    <Container
      primaryColor={colors.primary}
      secondaryColor={colors.secondary}
    >
      <WordCloudHeading
        image={module.image}
        title={module.title}
        subtitle={module.subtitle}
      />

      <WordCloudResults
        primaryColor={colors.primary}
        secondaryColor={colors.secondary}
        data={cloud}
      />
    </Container>
  );
};

export default WordCloudPresenter;
