import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Checkbox, Row, Col, Button, Icon } from 'antd';
import { useClientEmojiPacks, useEmojiPacks } from '../../hooks/emojis';

const EmojiPack = ({ pack, visible, available, onChange }) => {
  /** @type {React.MutableRefObject<HTMLInputElement>} */
  const refCheckbox = useRef();

  const handleVisibility = () => onChange(pack.id, {
    visible: !visible,
    available: !visible && available,
  });
  const handleAvailability = () => onChange(pack.id, {
    visible,
    available: !available,
  });

  useEffect(() => {
    refCheckbox.current.checked = visible && available;
  }, [visible, available]);

  return (
    <Row key={`emoji-pack-${pack.id}`}>
      <Col span={5}>
        <Button onClick={handleVisibility}>
          {visible ? <Icon type="eye" /> : <Icon type="eye-invisible" />}
        </Button>
      </Col>
      <Col span={12} style={visible ? {} : { opacity: 0.2 }}>
        <label htmlFor={`emoji-pack-${pack.id}`}>
          <input
            value={pack.id}
            type="checkbox"
            ref={refCheckbox}
            id={`emoji-pack-${pack.id}`}
            onChange={handleAvailability}
            disabled={!visible}
          />
          <strong>{pack.name}</strong>
          <Row justify="start" gutter={12}>
            {pack.emojis.map((emoji) => (
              <img
                key={`emoji-${emoji.id}`}
                className="reaction-image"
                style={{ width: 48, height: 48 }}
                alt={emoji.name}
                src={`/images/reactions/${emoji.name}.png`}
              />
            ))}
          </Row>
        </label>
      </Col>
    </Row>
  );
};

const EmojiPackFormFields = ({ client, valueRef, submitRef }) => {
  const [emojiPacks, refetchEmojiPacks] = useEmojiPacks();
  const [clientEmojiPacks, refetchClientEmojiPacks] = useClientEmojiPacks(
    client ? client.id : undefined,
  );
  const [visibleEmojiPacks, setVisibleEmojiPacks] = useState(null);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    submitRef.current = () => Promise.all([
      refetchEmojiPacks,
      refetchClientEmojiPacks,
    ]);
  }, [submitRef, refetchEmojiPacks, refetchClientEmojiPacks]);

  // load initial state
  useEffect(() => {
    if (!clientEmojiPacks) return;
    if (visibleEmojiPacks) return;

    setVisibleEmojiPacks(
      clientEmojiPacks.map((pack) => ({
        id: pack.id,
        available: pack.available,
      })),
    );
  }, [clientEmojiPacks, visibleEmojiPacks]);

  useEffect(() => {
    if (!visibleEmojiPacks) return;
    if (valueRef) {
      // eslint-disable-next-line no-param-reassign
      valueRef.current = visibleEmojiPacks.reduce(
        (result, { id, available }) => [...result, { id, available }],
        [],
      );
    }
  }, [valueRef, visibleEmojiPacks]);

  const visibleMap = useMemo(
    () => {
      if (!emojiPacks || !visibleEmojiPacks) return {};
      return emojiPacks.reduce((map, pack) => {
        const isVisible = visibleEmojiPacks.some(
          ({ id }) => pack.id === id,
        );
        return { ...map, [pack.id]: isVisible };
      }, {});
    },
    [emojiPacks, visibleEmojiPacks],
  );
  const availableMap = useMemo(
    () => {
      if (!emojiPacks || !visibleEmojiPacks) return {};
      return emojiPacks.reduce((map, pack) => {
        const isAvailable = visibleEmojiPacks.some(
          ({ id, available }) => pack.id === id && available,
        );
        return { ...map, [pack.id]: isAvailable };
      }, {});
    },
    [emojiPacks, visibleEmojiPacks],
  );

  const handleChange = (id, { visible, available }) => {
    setVisibleEmojiPacks((visiblePacks) => emojiPacks
      .reduce((result, pack) => {
        if (String(pack.id) === String(id)) {
          if (!visible) return result;
          return [...result, { id, visible, available }];
        }
        return [...result, {
          id: pack.id,
          visible: (visiblePacks || []).some(
            (visiblePack) => visiblePack.id === pack.id,
          ),
          available: (visiblePacks || []).some(
            (visiblePack) => visiblePack.id === pack.id && visiblePack.available,
          ),
        }];
      }, [])
      .filter((pack) => pack.visible));
  };

  if (!emojiPacks) return null;

  return (
    <Checkbox.Group>
      {emojiPacks.map((pack) => (
        <EmojiPack
          pack={pack}
          onChange={handleChange}
          visible={visibleMap[pack.id]}
          available={availableMap[pack.id]}
          key={`emoji-pack-${pack.id}`}
        />
      ))}
    </Checkbox.Group>
  );
};

export default EmojiPackFormFields;
