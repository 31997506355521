import { gql } from 'apollo-boost';

const LOGIN = gql`
    mutation Login($payload: LoginInput!){
        login(payload: $payload) {
            id
            username
            fullname
            token
            role
            client_id
        }
    }
`;

export default LOGIN;
