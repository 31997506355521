import { gql } from 'apollo-boost';

const GET_ALL_COMMENTS = gql`
    query allComments($module_id: Int!, $order: CommentsOrder) {
        allComments(module_id: $module_id, order: $order) {
            cursor
            comments {
                id
                author
                text
                parent_id
                is_hidden
                createdAt
                is_moderated
                tracking_id
                total_upvotes
            }
        }
    }

`;

export default GET_ALL_COMMENTS;
