import { useEffect } from 'react';

export default function useKeyboardListener(handler) {
  useEffect(() => {
    document.addEventListener('keyup', handler);

    return () => {
      document.removeEventListener('keyup', handler);
    };
  }, [handler]);
}
