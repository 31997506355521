import React, { useState } from 'react';

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    margin: '10px 0',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  closeButton: {
    padding: '10px 20px',
    border: '1px',
    margin: '10px',
    cursor: 'pointer',
  },
};

const PopupModal = ({
  showModal,
  setShowModal,
  onEventTitleChange,
  eventValue,
  onDomainChange,
  domainValue,
  handleDuplicateEvent,
}) => {
  if (!showModal) {
    return null;
  }

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <h2>Duplicate Event</h2>
        <p>Are you sure you want to create a new event { eventValue }? </p>
        <h3>Event Name</h3>
        <input
          type="text"
          value={eventValue}
          onChange={onEventTitleChange}
          style={styles.textField}
        />
        <h3>Event domain</h3>
        <input
          type="text"
          value={domainValue}
          onChange={onDomainChange}
          style={styles.textField}
        />
        {/* eslint-disable-next-line react/button-has-type */}
        <div>
          <button
            onClick={() => {
              setShowModal(false);
            }}
            style={styles.closeButton}
          >
            CANCEL
          </button>
          <button
            disabled={eventValue.length < 2 || domainValue.length < 2}
            onClick={() => {
              setShowModal(false);
              handleDuplicateEvent();
            }}
            style={styles.closeButton}
          >
            CONFIRM
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
