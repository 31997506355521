import { useMemo } from 'react';
import { useEvent } from './providers';

export const defaultEventColors = {
  primary_color: '#1B1464',
  secondary_color: '#aaaaaa',
};

export function useEventColors() {
  const [event] = useEvent();
  const primary = useMemo(
    () => event?.primary_color ?? defaultEventColors.primary_color,
    [event?.primary_color],
  );
  const secondary = useMemo(
    () => event?.secondary_color ?? defaultEventColors.secondary_color,
    [event?.secondary_color],
  );
  return useMemo(
    () => ({ primary, secondary }),
    [primary, secondary],
  );
}
