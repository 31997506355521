import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo } from 'react';
import { FIND_EMOJI_PACK, LIST_ALL_EMOJI_PACKS, LIST_ALL_EMOJIS } from '../graphql/queries/emojis/emojis';
import { LIST_CLIENT_EMOJI_PACKS, LIST_CLIENT_EMOJIS } from '../graphql/queries/emojis/clientEmojis';

const useEmojis = () => {
  const { data, refetch } = useQuery(LIST_ALL_EMOJIS, {
    fetchPolicy: 'network-only',
  });
  return [data, refetch];
};
const useEmojiPacks = () => {
  const { data, refetch } = useQuery(LIST_ALL_EMOJI_PACKS, {
    fetchPolicy: 'network-only',
  });
  const emojiPacks = useMemo(() => {
    if (!data) return null;
    return data.emojiPacks;
  }, [data]);

  return [emojiPacks, refetch];
};
const useEmojiPack = (id) => {
  const [data, { refetch }] = useLazyQuery(FIND_EMOJI_PACK);
  useEffect(() => {
    if (id) {
      // noinspection JSIgnoredPromiseFromCall
      refetch({ id });
    }
  }, [id]);

  return [data, refetch];
};

const useClientEmojis = (client_id) => {
  const { data, refetch } = useQuery(LIST_CLIENT_EMOJIS, { variables: { client_id } });
  const emojis = useMemo(
    () => (data ? data.clientEmojis : null),
    [data],
  );

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    refetch({ client_id });
  }, [client_id]);

  return [emojis, refetch];
};
const useClientEmojiPacks = (client_id) => {
  const [load, { data }] = useLazyQuery(LIST_CLIENT_EMOJI_PACKS);
  const emojiPacks = useMemo(
    () => {
      if (!data) return null;
      return data.clientEmojiPacks.sort(
        (a, b) => b.available - a.available,
      );
    },
    [data],
  );

  useEffect(() => {
    if (typeof client_id === 'number' && client_id >= 0) {
      load({ variables: { client_id } });
    }
  }, [client_id]);

  return [
    emojiPacks,
    useCallback((new_client_id = client_id) => {
      load({ variables: { client_id: new_client_id } });
    }, [load, client_id]),
  ];
};

export {
  useEmojis,
  useEmojiPacks,
  useEmojiPack,
  useClientEmojis,
  useClientEmojiPacks,
};
