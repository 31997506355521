import React from 'react';
import { Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';
import useMenuCollapse from '../../hooks/useMenuCollapse';

const Sidebar = () => {
  // we need user object here
  const { user } = useLogin();
  const { SubMenu } = Menu;
  const { isCollapsed } = useMenuCollapse();
  const defaultOpenKeys = [];
  if (!isCollapsed()) {
    defaultOpenKeys.push('supermenu');
  }
  // There could be a better way to do this..
  // eslint-disable-next-line no-undef
  const currentLocation = window.location.pathname;
  let defaultOpen;
  if (currentLocation.split('/')[1] === '') {
    defaultOpen = 'dashboard';
  } else {
    // eslint-disable-next-line prefer-destructuring
    defaultOpen = currentLocation.split('/')[1];
  }
  return (
    <div className="sidebar">
      <Menu
        theme="votemo"
        defaultSelectedKeys={[defaultOpen]}
        selectedKeys={[defaultOpen]}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
      >
        <Menu.Item key="events">
          <Icon type="youtube" />
          <span>Events</span>
          <NavLink className="nav-text" to="/events" />
        </Menu.Item>
        <Menu.Item key="sessions">
          <i className="fas fa-film sidebar-icon-fa" />
          <span>Sessions</span>
          <NavLink className="nav-text" to="/sessions" />
        </Menu.Item>
        {user.role === 'superadmin' && (
          <SubMenu
            key="supermenu"
            title={(
              <span>
                <Icon type="control" />
                <span>Admin</span>
              </span>
            )}
          >
            <Menu.Item key="client">
              <Icon type="usergroup-add" />
              <span>Clients</span>
              <NavLink className="nav-text" to="/clients" />
            </Menu.Item>
            <Menu.Item key="user">
              <Icon type="user" />
              <span>Users</span>
              <NavLink className="nav-text" to="/users" />
            </Menu.Item>
            <Menu.Item key="api-settings">
              <Icon type="setting" />
              <span>Settings</span>
              <NavLink className="nav-text" to="/settings" />
            </Menu.Item>
          </SubMenu>
        )}
        {user.role === 'admin' && (
          <Menu.Item key="user">
            <Icon type="user" />
            <span>Users</span>
            <NavLink className="nav-text" to="/users" />
          </Menu.Item>
        )}
      </Menu>
    </div>
  );
};

export default Sidebar;
