import { Collapse } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactionFormFields from '../reactions';
import { useModuleFields } from '../../../ModuleForm/ModuleForm.hooks';
import { useEvent } from '../../../../hooks/providers/events';

const ReactionsCollapse = ({ form, module, onChange }) => {
  const { t } = useTranslation();
  const [event] = useEvent();
  const { getFieldDecorator, getFieldValue } = form;
  const moduleFields = useModuleFields(getFieldValue('type'));
  const client = useMemo(
    () => event?.client,
    [event],
  );
  if (!moduleFields.reactions) return null;

  return (
    <Collapse style={{ marginBottom: 16 }}>
      <Collapse.Panel key="reactions" header={t('modules.form.reactions.label')}>
        <ReactionFormFields
          getFieldDecorator={getFieldDecorator}
          module={module}
          client={client}
          onChange={onChange}
        />
      </Collapse.Panel>
    </Collapse>
  );
};
export default ReactionsCollapse;
