import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Select, Switch, Button, message } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import CLIENT_LIST from '../../../graphql/queries/clientList';
import useLogin from '../../../hooks/useLogin';

const UserForm = (props) => {
  const { user: loggedinUser } = useLogin();
  const { form, user = {}, onSubmit } = props;
  const { getFieldDecorator, validateFields } = form;
  const { Option } = Select;
  const { t } = useTranslation();
  const firstName = user.first_name || '';
  const lastName = user.last_name || '';
  const username = user.username || '';
  const email = user.email || '';
  const [roleId, setRoleId] = useState(user.role_id || undefined);
  const clientId = user.client_id ? user.client_id : undefined;
  const active = typeof user.active !== 'undefined' ? user.active : true;
  const buttonText = user.first_name ? t('user.form.button_update') : t('user.form.button_create');
  const [clientList, setClientList] = useState(false);
  const { data: clientData } = useQuery(CLIENT_LIST, {
    skip: loggedinUser.role !== 'superadmin',
  });
  useEffect(() => {
    if (clientData) {
      setClientList(clientData);
    }
  }, [clientData, setClientList]);

  const onFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const payload = await validateFields();
      if (loggedinUser.role !== 'superadmin') {
        payload.client_id = loggedinUser.client_id;
      }
      onSubmit(payload);
    } catch (exception) {
      message.error('Form submission failed. Check below for details');
    }
  };

  const renderForm = () => (
    <Form onSubmit={onFormSubmit} className="user-form">
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="First name">
            {getFieldDecorator('first_name', {
              rules: [{ required: true, whiteSpace: true, message: 'Please enter first name!' }],
              initialValue: firstName,
            })(<Input placeholder="First name" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="Last name">
            {getFieldDecorator('last_name', {
              rules: [{ required: true, whiteSpace: true, message: 'Please enter last name!' }],
              initialValue: lastName,
            })(<Input placeholder="Last name" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="Username">
            {getFieldDecorator('username', {
              rules: [{ whiteSpace: true, message: 'Please enter username!' }],
              initialValue: username,
            })(<Input placeholder="Username" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [
                { type: 'email', message: 'Please enter a valid email' },
                { required: true, whiteSpace: true, message: 'Please enter email!' },
              ],
              initialValue: email,
            })(<Input placeholder="email" />)}
          </Form.Item>
        </Col>
      </Row>
      {user.first_name && (
        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item label="(Current) Password">
              {getFieldDecorator('old_password', {})(<Input.Password placeholder="(Current) password" />)}
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item label={user.first_name ? '(New) Password' : 'Password'}>
            {getFieldDecorator('password', {})(<Input.Password
              placeholder={user.first_name ? '(New) Password' : 'Password'}
            />)}
          </Form.Item>
        </Col>
      </Row>
      {loggedinUser.role === 'superadmin' ? (
        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item label="Role">
              {getFieldDecorator('role_id', {
                rules: [
                  { required: true, message: 'Please select a role!' },
                ],
                initialValue: roleId,
              })(
                <Select placeholder="Please select a role" onChange={(val) => setRoleId(val)}>
                  <Option value={1}>Super Admin</Option>
                  <Option value={2}>Admin</Option>
                  <Option value={3}>Editor</Option>
                  <Option value={4}>Moderator</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      ) : (
        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item label="Role">
              {getFieldDecorator('role_id', {
                rules: [
                  { required: true, message: 'Please select a role!' },
                ],
                initialValue: roleId,
              })(
                <Select placeholder="Please select a role" onChange={(val) => setRoleId(val)}>
                  <Option value={4}>Moderator</Option>
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {parseInt(roleId, 10) !== 1 && clientList.clients && (
        <Row type="flex" gutter={16}>
          <Col span={12}>
            <Form.Item label="Client">
              {getFieldDecorator('client_id', {
                rules: [
                  { required: true, message: 'Please select a client!' },
                ],
                initialValue: clientId,
              })(
                <Select placeholder="Please select a client">
                  {clientList.clients.map((client) => (
                    <Option key={client.id} value={client.id}>{client.company_name}</Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={12}>
          <Form.Item label="Active">
            {getFieldDecorator('active', { valuePropName: 'checked', initialValue: active })(
              <Switch />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={12}>
          <Form.Item>
            <Button block size="large" type="primary" htmlType="submit">
              {buttonText}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
  return (
    <div>
      {renderForm()}
    </div>
  );
};

export default Form.create()(UserForm);
