import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';

import { RouteBuilders } from '../../routes';

import { findValidModule, findValidSession } from './utils';
import { useEvent, useModule, useSession } from '../../hooks/providers';
import { useValidatorContext } from './validator-context';
import { useCombinedState } from '../../hooks/utils/useCombinedState';
import { PresenterInvalid } from './PresenterRedirect.styled';
import { useEventContext } from '../EventProvider';

function useRedirectState() {
  return useCombinedState({ loading: true, valid: false, redirect: false }, {
    loading: { loading: true },
    valid: { loading: false, valid: true },
    invalid: { loading: false, valid: false },

    redirect: (redirect) => ({ loading: false, valid: false, redirect }),
  });
}

function usePresenterRedirect() {
  const { loading, valid, result: validated } = useValidatorContext();
  const state = useRedirectState();

  const [event] = useEvent();
  const [session] = useSession();
  const [module] = useModule();
  const { event: eventFromContext } = useEventContext();

  return useMemo(() => {
    if (loading) return state.loading();
    if (valid) {
      const newSession = findValidSession(eventFromContext);
      if (newSession && newSession.id !== session.id) {
        const publishedModule = findValidModule(newSession);
        if (!publishedModule) return state.invalid();

        return state.redirect({
          eventId: eventFromContext.id,
          sessionId: newSession.id,
          moduleId: publishedModule.id,
        });
      }

      if (!newSession) {
        return state.invalid();
      }
      return state.valid();
    }

    if (!validated.event) return state.invalid();

    // invalid session
    if (!validated.session) {
      const liveSession = findValidSession(event);
      if (!liveSession) return state.invalid();

      const publishedModule = findValidModule(liveSession);
      return state.redirect({
        eventId: validated.event,
        sessionId: liveSession.id,
        moduleId: publishedModule.id,
      });
    }

    // invalid module
    if (!validated.module) {
      const publishedModule = findValidModule(session);
      if (!publishedModule) {
        const liveSession = findValidSession(event);
        if (!liveSession) return state.invalid();

        const liveModule = findValidModule(liveSession);
        return state.redirect({
          eventId: validated.event,
          sessionId: liveSession.id,
          moduleId: liveModule.id,
        });
      }

      return state.redirect({
        eventId: validated.event,
        sessionId: validated.session,
        moduleId: publishedModule.id,
      });
    }

    return state.valid();
  }, [loading, valid, validated, state, event, session, module]);
}

export default function PresenterRedirect({ children }) {
  const { valid, loading, redirect } = usePresenterRedirect();

  const url = useMemo(
    () => redirect && RouteBuilders.Presenter.ofModule(redirect),
    [redirect],
  );

  if (loading) return null;
  if (redirect) return <Redirect to={url} />;
  if (!valid) return <PresenterInvalid />;

  return children;
}
