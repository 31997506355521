export const lightTheme = {
  body: '#ffffff',
  text: '#333333',
  commentBackground: '#E3E3E3',
};

export const darkTheme = {
  body: '#1e1e1e',
  text: '#ffffff',
  commentBackground: '#3E3E3E',
};
