import { gql } from 'apollo-boost';
import useApiQuery from '../useApiQuery';

export const GET_EVENT = gql`
    query event($event_id: Int!){
        event: show(show_id: $event_id) {
            id
            name
            domain_name
            language
            image
            primary_color
            secondary_color
            description
            display_info
            display_header
            display_menu
            display_qr
            track_user
            poll_interval
            sessions: episodes {
                id
                name
                description
                display_info
                image
                date_live
                show_terms
                terms_location
                is_live
                modules {
                    id
                    type
                    published
                }
            }
            paymentProviders {
                reference
            }
            moderators {
                id
                first_name
                last_name
            }
            client {
                id
                whitelabel
                moderators {
                    id
                    first_name
                    last_name
                }
            }
            publicEvent: publicShow {
                id
                session: episode {
                    id
                    modules { id, type }
                }
            }
            created_at
            updated_at
        }
    }
`;

export default function useGetEvent(initialEventId) {
    return useApiQuery(
      GET_EVENT,
      'event',
      [initialEventId],
      (eventId) => {
          if (!eventId) return undefined;
          return { event_id: eventId };
      },
    );
}
