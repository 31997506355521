import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Button, Icon, message, Modal, Popconfirm, Popover, Table, DatePicker, Radio, Tooltip, Input } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import PopupModal from '../../components/PopupInputConfirmModal/PopupModal';

import DELETE_EVENT from '../../graphql/mutations/showDelete';
import LIST_PUBLIC_EVENTS from '../../graphql/queries/listPublicEvents';
import DUPLICATE_EVENT from '../../graphql/mutations/duplicateEvent';
import useLogin from '../../hooks/useLogin';
import { TooltipButton } from '../../components/common/TooltipButton';
import useTableFilter from '../../hooks/useTableFilter';
import { RouteBuilders } from '../../routes';
import constants from '../../constants';
import { getEventDomain } from '../../helpers';
import { isModulePresentable } from '../../enums/module.enums';

const EventsListPage = () => {
  const [duplicateEvent] = useMutation(DUPLICATE_EVENT, {
    refetchQueries: ['ListEvents'],
  });

  const { t } = useTranslation();
  // local state variables
  const [loadPublicEvents, { loading, error, data }] = useLazyQuery(LIST_PUBLIC_EVENTS, {
    fetchPolicy: 'no-cache',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [showExport, setShowExport] = useState(false);
  const [showChatExport, setShowChatExport] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eventTitle, setEventTitle] = useState('');
  const [eventDomain, setEventDomain] = useState('');
  const [dupeEventId, setDupeEventId] = useState(0);

  const handleChangeEventTitle = (e) => {
    setEventTitle(e.target.value);
  };
  const handleChangeDomain = (e) => {
    setEventDomain(e.target.value.replace(/[^a-z0-9-]+/g, '-').toLowerCase());
  };
  const handleDuplicateEventClick = async () => {
    try {
      const result = await duplicateEvent({
        variables: {
          payload: { event_id: dupeEventId, name: eventTitle, domain_name: eventDomain },
        },
      });
      if (result) message.success('Successfully duplicated event');
      setDupeEventId(0);
      setEventTitle('');
      loadPublicEvents({
        variables: { client_id: user.client_id },
      });
    } catch (e) {
      message.error(`Something went wrong duplicating event: ${e.message}`);
    }
  };

  let tableData = [];

  // we need user object here
  const { user, isModerator } = useLogin();

  useEffect(() => {
    if (!user.client_id) return;

    loadPublicEvents({
      variables: { client_id: user.client_id },
    });
  }, [user.client_id, loadPublicEvents]);

  const [deleteEvent] = useMutation(DELETE_EVENT);

  const onClickDelete = async (e, event_id) => {
    if (e && e.stopPropagation) e.stopPropagation();
    try {
      const result = await deleteEvent({
        variables: { event_id },
      });
      if (result) {
        const { event } = result.data;
        message.success(`Successfully deleted event ${event.name}`);
        loadPublicEvents({
          variables: { client_id: user.client_id },
        });
      }
    } catch (err) {
      message.error('Something went wrong');
    }
  };

  const nameFilter = useTableFilter('name');
  const domainFilter = useTableFilter('domain', null);

  const structureTableData = () => {
    const tableRows = data.events.map((event, index) => ({
      key: index + 1,
      name: event.name,
      description: event.description,
      id: event.id,
      domain: event.domain_name,
      presentation_url: ((publicEvent) => {
        // if the show has a live module
        if (!publicEvent) return undefined;
        if (!publicEvent.session) return undefined;
        if (!publicEvent.session.modules.length) return undefined;

        const eventId = event.id;
        const sessionId = publicEvent.session.id;
        const { id: moduleId } = publicEvent.session.modules.find(isModulePresentable) || {};
        if (!moduleId) return undefined;

        return RouteBuilders.Presenter.ofModule({ eventId, sessionId, moduleId });
      })(event.publicEvent),
    }));

    // @todo: Should probably get this data via request when needed
    const columns = [
      {
        title: t('events.table.information'),
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, { id, name, description }) => (
          <NavLink to={`/events/${id}`} style={{ display: 'flex', flexDirection: 'column', color: 'unset' }}>
            <span>{name}</span>
            {String(description || '').trim() ? (
              <small>{description}</small>
            ) : (
              <small style={{ opacity: 0.35 }}>No Description</small>
            )}
          </NavLink>
        ),
        ...nameFilter,
      },
      {
        title: t('events.table.domain'),
        dataIndex: 'domain',
        width: 400,
        sorter: (a, b) => a.domain.localeCompare(b.domain),
        render: (domain) => {
          const url = getEventDomain(domain);
          return (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'inline-flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: 'black',
              }}
            >
              <span>{domain}</span>
              <span style={{ opacity: 0.35 }}>.{constants.domainBase}</span>
            </a>
          );
        },
        ...domainFilter,
      },
      {
        title: '',
        key: 'action',
        width: 150,
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PopupModal
              showModal={showModal}
              setShowModal={setShowModal}
              onEventTitleChange={handleChangeEventTitle}
              eventValue={eventTitle}
              onDomainChange={handleChangeDomain}
              domainValue={eventDomain}
              handleDuplicateEvent={handleDuplicateEventClick}
            />
            <Button
              type="link"
              size="small"
              onClick={() => {
                setDupeEventId(record.id);
                setShowModal(true);
              }}
            >
              <Icon type="copy" />
            </Button>
            {record.presentation_url ? (
              <Button
                type="link"
                size="small"
                onClick={(event) => event.stopPropagation()}
                href={record.presentation_url}
                target="_blank"
              >
                <Icon type="desktop" />
              </Button>
            ) : (
              <Popover
                content="Unable to present"
                trigger="hover"
                placement="bottomLeft"
              >
                <Button
                  type="link"
                  size="small"
                  onClick={(event) => event.stopPropagation()}
                  target="_blank"
                  disabled
                >
                  <Icon type="desktop" />
                </Button>
              </Popover>
            )}
            {!isModerator && (
              <Popconfirm
                title="Are you sure you want to delete this event?"
                onConfirm={(event) => onClickDelete(event, record.id)}
              >
                <Button type="link" size="small">
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            )}
          </div>
        ),
      },
    ];
    return {
      columns,
      tableRows,
    };
  };

  if (loading) return t('common.loading');

  if (error) return `${t('common.error')}! ${error.message}`;

  if (data && data.events) {
    tableData = structureTableData();
  }

  return (
    <div>
      <header className="page-header">
        <h1>{isModerator
          ? t('events.labels.my_events')
          : t('events.labels.events')}
        </h1>
        <div>
          <DataExportModal visible={showExport} onClose={() => setShowExport(false)} />
          <ChatExportModal visible={showChatExport} onClose={() => setShowChatExport(false)} />
          <Button onClick={() => setShowChatExport(true)}>
            <span>{t('common.buttons.export_chat')}</span>
          </Button>
          <Button 
            onClick={() => setShowExport(true)}
            style={{ marginLeft: 10 }}
          >
            <span>{t('common.buttons.export')}</span>
          </Button>
          {user.client_id && !isModerator && (
            <TooltipButton
              tooltip={t('events.tooltips.add_new')}
              buttonProps={{
                type: 'primary',
                size: 'default',
                href: `/clients/${user.client_id}/create-event`,
                style: {
                  marginLeft: 10,
                },
              }}
            >
              {t('events.buttons.add_new')}
            </TooltipButton>
          )}
        </div>
      </header>
      <Table
        columns={tableData.columns}
        dataSource={tableData.tableRows}
        size="small"
        pagination={{
          current: currentPage,
          pageSize: currentPageSize,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
          onShowSizeChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
        }}
      />
    </div>
  );
};

/**
 * @param {Moment} date
 */
function formatDate(date) {
  return date.format('YYYY-MM-DD');
}

function useClientVotesUrl({ start, end }) {
  const { user } = useLogin();
  // TODO: Refactor this URL
  const baseUrl = `${process.env.REACT_APP_REST_URL}/data-export/clients/:client_id/votes.xlsx`;
  const clientId = useMemo(() => user?.client_id, [user]);

  return useMemo(() => {
    let url = baseUrl.replace(':client_id', clientId);
    if (start || end) url += '?';
    if (start) url += `start=${encodeURIComponent(formatDate(start))}`;
    if (start && end) url += '&';
    if (end) url += `end=${encodeURIComponent(formatDate(end))}`;
    return url;
  }, [baseUrl, start, end]);
}

function useClientChatsUrl({ start, end, sheetID }) {
  const { user } = useLogin();
  // TODO: Refactor this URL
  const baseUrl = `${process.env.REACT_APP_REST_URL}/chat-export/clients/:client_id/chats.xlsx`;
  const clientId = useMemo(() => user?.client_id, [user]);

  return useMemo(() => {
    let url = baseUrl.replace(':client_id', clientId);
    if (start || end) url += '?';
    if (start) url += `start=${encodeURIComponent(formatDate(start))}`;
    if (start && end) url += '&';
    if (end) url += `end=${encodeURIComponent(formatDate(end))}`;
    if(sheetID) url += `&sheet_id=${sheetID}`
    return url;
  }, [baseUrl, start, end, sheetID]);
}

function DataExportModal({ visible, onClose }) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = useCallback(([start, end]) => {
    setStartDate(start);
    setEndDate(end);
  }, [setStartDate, setEndDate]);
  const clientVotesUrl = useClientVotesUrl({
    start: startDate ?? null,
    end: endDate ?? null,
  });

  return (
    <Modal
      title={t('events.export_data.title')}
      visible={visible}
      onCancel={onClose}
      children={(
        <>
          <Radio.Group style={{ marginBottom: 10 }}>
            <Radio defaultChecked>{t('events.export_data.body')}</Radio>
          </Radio.Group>
          <DatePicker.RangePicker onChange={onChange} />
        </>
      )}
      footer={(
        <div>
          <Button type="link" onClick={onClose}>{t('common.buttons.close')}</Button>
          <Button type="primary" href={clientVotesUrl} target="_blank">{t('common.buttons.export')}</Button>
        </div>
      )}
    />
  );
}

function ChatExportModal({ visible, onClose }) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sheetID, setSheetID] = useState(null);
  const onChange = useCallback(([start, end, sheetID]) => {
    setStartDate(start);
    setEndDate(end);
    setSheetID(sheetID);
  }, [setStartDate, setEndDate]);
  const clientChatsUrl = useClientChatsUrl({
    start: startDate ?? null,
    end: endDate ?? null,
    sheetID: sheetID ?? null,
  });

  return (
    <Modal
      title={t('events.export_chat.title')}
      visible={visible}
      onCancel={onClose}
      children={(
        <>
          <Radio.Group style={{ marginBottom: 10 }}>
            <Radio defaultChecked>{t('events.export_chat.body')}</Radio>
          </Radio.Group>
          <DatePicker.RangePicker onChange={onChange} />
          <Input
            placeholder={t('events.export_chat.sheet_ID')}
            value={sheetID}
            onChange={(e) => setSheetID(e.target.value)}
            style={{ marginTop: 10 }}
          />
        </>
      )}
      footer={(
        <div>
          <Button type="link" onClick={onClose}>{t('common.buttons.close')}</Button>
          <Button type="primary" href={clientChatsUrl} target="_blank" onClick={onClose} style={{ marginLeft: 10 }}>{t('common.buttons.download_csv')}</Button>
        </div>
      )}
    />
  );
}

export default EventsListPage;
