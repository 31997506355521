import { gql } from 'apollo-boost';

const CLIENT_UPDATE = gql`
    mutation clientUpdate($client_id: Int!, $payload: ClientUpdateInput!){
        updateClient(client_id: $client_id, payload: $payload) {
            id
            company_name
        }
    }
`;

export default CLIENT_UPDATE;
