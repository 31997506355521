import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export default function useMappedParams(mapper) {
  const params = useParams();

  return useMemo(() => {
    if (!mapper) return params;
    if (typeof mapper === 'function') return mapper(params);

    return Object.keys(mapper)
      .reduce((mappedParams, key) => {
        const value = mapper[key](params, key, mappedParams);
        return { ...mappedParams, [key]: value };
      }, {});
  }, [params, mapper]);
}
