const animations = {};

const registerAnimation = (name, { image, idleImage, animation, offset, width, height }) => {
  Object.assign(animations, {
    [name]: { image, idleImage, animation, width, height, offset },
  });
};

// -------------- Offset calibrated
registerAnimation('clapping', {
  animation: { name: 'arClap', duration: 1700, iterations: 2, steps: 60 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/clapping.png`,
  width: 113,
  height: 130,
  offset: { y: 3 },
});
registerAnimation('clapping-sand', {
  animation: { name: 'arClapSand', duration: 1000, iterations: 2, steps: 25 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/clapping-sand.png`,
  width: 100,
  height: 106,
  offset: { y: -5 },
});
registerAnimation('clapping-yellow', {
  animation: { name: 'arClapYellow', duration: 1000, iterations: 2, steps: 31 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/clapping-yellow.png`,
  width: 115,
  height: 130,
  offset: { y: -5 },
});

registerAnimation('dancing-sand', {
  animation: { name: 'arDancingSand', duration: 1700, iterations: 2, steps: 80 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/dancing-sand.png`,
  width: 122,
  height: 125,
});
registerAnimation('dancing-yellow', {
  animation: { name: 'arDancingYellow', duration: 1700, iterations: 2, steps: 80 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/dancing-yellow.png`,
  width: 125,
  height: 118,
  offset: { y: -5 },
});

registerAnimation('sleepy-sand', {
  animation: { name: 'arSleepySand', duration: 3000, iterations: 2, steps: 96 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/sleepy-sand.png`,
  width: 70,
  height: 144,
  offset: { y: -3 },
});
registerAnimation('sleepy-yellow', {
  animation: { name: 'arSleepyYellow', duration: 3000, iterations: 2, steps: 99 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/sleepy-yellow.png`,
  width: 72,
  height: 144,
  offset: { y: -3 },
});

registerAnimation('heart', {
  animation: { name: 'arHeart', duration: 3000, iterations: 2, steps: 91 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/heart.png`,
  width: 187,
  height: 300,
  offset: { y: -5 },
});
registerAnimation('heart-sand', {
  animation: { name: 'arHeartSand', duration: 2500, iterations: 2, steps: 97 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/heart-sand.png`,
  width: 131,
  height: 217,
  offset: { y: -5 },
});
registerAnimation('heart-yellow', {
  animation: { name: 'arHeartYellow', duration: 2500, iterations: 2, steps: 130 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/heart-yellow.png`,
  width: 120,
  height: 217,
  offset: { y: -5 },
});
registerAnimation('cannes_heart', {
  animation: { name: 'arCannesHeart', duration: 3000, iterations: 1, steps: 77 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_heart_sprite.png`,
  width: 192,
  height: 370,
  offset: { y: -5 },
});

registerAnimation('sad', {
  animation: { name: 'arSad', duration: 3000, iterations: 2, steps: 126 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/sad.png`,
  width: 192,
  height: 81,
});
registerAnimation('mad', {
  animation: { name: 'arMad', duration: 3000, iterations: 2, steps: 110 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/mad.png`,
  width: 70,
  height: 115,
});

// -------------- Offset NOT calibrated
registerAnimation('danfoss-agreed', {
  animation: { name: 'arDanfossAgreed', duration: 3000, iterations: 2, steps: 75 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/danfoss-agreed.png`,
  width: 150,
  height: 370,
});
registerAnimation('danfoss-clapping', {
  animation: { name: 'arDanfossClapping', duration: 3000, iterations: 2, steps: 64 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/danfoss-clapping.png`,
  width: 150,
  height: 350,
});
registerAnimation('danfoss-party', {
  animation: { name: 'arDanfossParty', duration: 3000, iterations: 2, steps: 89 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/danfoss-party.png`,
  width: 150,
  height: 370,
});
registerAnimation('kone-care', {
  animation: { name: 'arKoneCare', duration: 3000, iterations: 2, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/kone-care.png`,
  width: 200,
  height: 200,
});
registerAnimation('kone-collaboration', {
  animation: { name: 'arKoneCollaboration', duration: 3000, iterations: 2, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/kone-collaboration.png`,
  width: 200,
  height: 200,
});
registerAnimation('kone-courage', {
  animation: { name: 'arKoneCourage', duration: 3000, iterations: 2, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/kone-courage.png`,
  width: 200,
  height: 200,
});
registerAnimation('kone-customer', {
  animation: { name: 'arKoneCustomer', duration: 3000, iterations: 2, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/kone-customer.png`,
  width: 200,
  height: 200,
});
registerAnimation('cannes_clapping', {
  animation: { name: 'arCannesClapping', duration: 3000, iterations: 1, steps: 65 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_clapping_sprite.png`,
  width: 192,
  height: 370,
});
registerAnimation('cannes_confetti', {
  animation: { name: 'arCannesConfetti', duration: 3000, iterations: 1, steps: 77 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_confetti_sprite.png`,
  width: 192,
  height: 370,
});
registerAnimation('cannes_flame', {
  animation: { name: 'arCannesFlame', duration: 3000, iterations: 1, steps: 65 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_flame_sprite.png`,
  width: 192,
  height: 370,
});
registerAnimation('cannes_juan', {
  animation: { name: 'arCannesJuan', duration: 3000, iterations: 1, steps: 125 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_juan_sprite.png`,
  width: 144,
  height: 278,
});
registerAnimation('cannes_lions', {
  animation: { name: 'arCannesLions', duration: 3000, iterations: 1, steps: 112 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_lions_sprite.png`,
  idleImage: `${process.env.PUBLIC_URL}/images/reactions/cannes_lions.png`,
  width: 192,
  height: 370,
});
registerAnimation('cannes_perfect', {
  animation: { name: 'arCannesPerfect', duration: 3000, iterations: 1, steps: 112 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_perfect_sprite.png`,
  width: 192,
  height: 370,
});
registerAnimation('cannes_tina', {
  animation: { name: 'arCannesTina', duration: 3000, iterations: 1, steps: 125 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/cannes_tina_sprite.png`,
  width: 144,
  height: 278,
});
registerAnimation('eas_clap', {
  animation: { name: 'arEasClap', duration: 3000, iterations: 1, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/eas_clap_sprite.png`,
  width: 200,
  height: 200,
});
registerAnimation('eas_heart', {
  animation: { name: 'arEasHeart', duration: 3000, iterations: 1, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/eas_heart_sprite.png`,
  width: 200,
  height: 200,
});
registerAnimation('eas_rand', {
  animation: { name: 'arEasRand', duration: 3000, iterations: 1, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/eas_rand_sprite.png`,
  width: 200,
  height: 200,
});
registerAnimation('eas_pall', {
  animation: { name: 'arEasPall', duration: 3000000, iterations: 1, steps: 120 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/eas_pall_sprite.png`,
  width: 200,
  height: 200,
});
registerAnimation('ascential_clap', {
  animation: { name: 'arAscentialClap', duration: 3000, iterations: 1, steps: 65 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/ascential_clap.png`,
  width: 194,
  height: 370,
});
registerAnimation('ascential_heart', {
  animation: { name: 'arAscentialHeart', duration: 3000, iterations: 1, steps: 77 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/ascential_heart.png`,
  width: 194,
  height: 370,
});
registerAnimation('ascential_perfect', {
  animation: { name: 'arAscentialPerfect', duration: 3000, iterations: 1, steps: 111, mode: 'forwards' },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/ascential_perfect.png`,
  width: 194,
  height: 370,
});
registerAnimation('ascential_flames', {
  animation: { name: 'arAscentialFlames', duration: 3000, iterations: 1, steps: 65 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/ascential_flames.png`,
  width: 194,
  height: 370,
});
registerAnimation('ascential_confetti', {
  animation: { name: 'arAscentialConfetti', duration: 3000, iterations: 1, steps: 77 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/ascential_confetti.png`,
  width: 194,
  height: 370,
});
registerAnimation('coca-cola_cola', {
  animation: { name: 'arCocaCola-Cola', duration: 3000, iterations: 1, steps: 115 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/coca-cola_cola.png`,
  width: 260,
  height: 376,
});
registerAnimation('coca-cola_sprite', {
  animation: { name: 'arCocaCola-Sprite', duration: 3000, iterations: 1, steps: 95 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/coca-cola_sprite.png`,
  width: 250,
  height: 270,
});
registerAnimation('coca-cola_fanta', {
  animation: { name: 'arCocaCola-Fanta', duration: 3000, iterations: 1, steps: 38 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/coca-cola_fanta.png`,
  width: 250,
  height: 250,
});
registerAnimation('coca-cola_water', {
  animation: { name: 'arCocaCola-Water', duration: 3000, iterations: 1, steps: 90 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/coca-cola_water.png`,
  width: 250,
  height: 250,
});
registerAnimation('heart_green', {
  animation: { name: 'arHeart_green', duration: 2000, iterations: 1, steps: 64 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/heart_green.png`,
  width: 200,
  height: 200,
});
registerAnimation('heart_yellow', {
  animation: { name: 'arHeart_yellow', duration: 2000, iterations: 1, steps: 64 },
  image: `${process.env.PUBLIC_URL}/images/reactions/animated/heart_yellow.png`,
  width: 200,
  height: 200,
});

const AnimationStyles = Object.entries(animations).reduce((styles, [key, item]) => {
  const { image, animation, width, height } = item;
  const style = [
    `background-image: url(${image})`,
    `width: ${width}px`,
    `height: ${height}px`,
    `animation-name: ${animation.name}`,
    `animation-duration: ${animation.duration}ms`,
    `animation-iteration-count: ${animation.iterations}`,
    `animation-timing-function: steps(${animation.steps})`,
    `animation-fill-mode: ${animation.mode || 'backwards'}`,
  ];
  return {
    ...styles,
    [key]: `${style.join(';')};`,
  };
}, {});

const animationStyle = (image) => AnimationStyles[image];

export default { animations, registerAnimation, style: animationStyle };
