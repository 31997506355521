import { useMemo } from 'react';
import { gql } from 'apollo-boost';

import { useModuleUpdateQuery } from '../../../components/Presenter/hooks/useModuleUpdates';

const GET_METER_VOTE_RESULTS = gql`
    query meterVoteResults($module_id: Int!, $uid: String) {
        results: meterVoteResults(module_id: $module_id, uid: $uid) {
            result
            value
        }
    }
`;

export default function useMoodMeterResult(moduleId) {
  // TODO: This does not listen to mood meter changes
  const [data, info] = useModuleUpdateQuery(
    moduleId,
    GET_METER_VOTE_RESULTS,
    useMemo(
      () => ({ module_id: moduleId }),
      [moduleId],
    ),
  );

  if (!data) return [0, info];

  const { result } = data.results;
  return [result, info];
}
