import { gql } from 'apollo-boost';

const DELETE_SESSION = gql`
    mutation deleteSession($session_id: Int!){
        session: deleteEpisode(episode_id: $session_id) {
            id
            name
        }
    }
`;

export default DELETE_SESSION;
