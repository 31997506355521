import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../../components/Header';
import SettingsList from '../../components/Settings/List';
import useMenuCollapse from '../../hooks/useMenuCollapse';
import Sidebar from '../../components/Sidebar';
import UserAdd from '../../components/User/add';
import UserList from '../../components/User/List';
import UserUpdate from '../../components/User/update';
import UserDetail from '../../components/User/detail';

import { ClientPages, EventPages, ModulePages, SessionPages } from '../../pages';
import { Routes } from '../../routes';

const { Content, Sider } = Layout;
const App = () => {
  const { setCollapsed, isCollapsed } = useMenuCollapse();
  return (
    <Layout>
      <div className="app">
        <Header />
        <Layout className="layout-theme-votemo">
          <div className="app-content">
            <Sider
              theme="votemo"
              collapsible
              defaultCollapsed={isCollapsed()}
              onCollapse={(collapsed) => setCollapsed(collapsed)}
              width="270"
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
              }}
            >
              <Sidebar />
            </Sider>
            <Layout className="layout-theme-votemo">
              <Content>
                <div className="main-content">
                  <Switch>
                    {/* Users */}
                    <Route path="/users/create" component={UserAdd} />
                    <Route path="/users/:user_id/detail" component={UserDetail} />
                    <Route path="/users/:user_id" component={UserUpdate} />
                    <Route path="/users" component={UserList} />
                    {/* Clients */}
                    <Route path="/clients/create" component={ClientPages.Create} />
                    <Route path="/clients/:client_id/create-event" component={EventPages.Create} />
                    <Route path="/clients/:client_id" component={ClientPages.Update} />
                    <Route path="/clients" component={ClientPages.List} />
                    {/* Events */}
                    <Route
                      path="/events"
                      component={EventPages.List}
                      exact
                    />
                    <Route
                      path={Routes.ofEvent}
                      component={EventPages.Update}
                      exact
                    />
                    {/* Sessions */}
                    <Route path="/sessions" component={SessionPages.List} exact />
                    <Route path="/sessions/create" component={SessionPages.Create} />
                    <Route path={`${Routes.ofEvent}/sessions/create`} component={SessionPages.Create} />
                    <Route
                      path={Routes.ofSession}
                      component={SessionPages.Update}
                      exact
                    />
                    <Route
                      path={`${Routes.ofSession}/create-module`}
                      component={ModulePages.Create}
                    />
                    {/* Modules */}
                    <Route
                      path={Routes.ofModule}
                      component={ModulePages.Update}
                      exact
                    />
                    <Route
                      path={`${Routes.ofModule}/vote-results`}
                      component={ModulePages.VoteResults}
                    />
                    <Route
                      path={`${Routes.ofModule}/meter-results`}
                      component={ModulePages.MeterResults}
                    />
                    <Route
                      path={`${Routes.ofModule}/click-results`}
                      component={ModulePages.ClickResults}
                    />
                    <Route
                      path={`${Routes.ofModule}/comments`}
                      component={ModulePages.Comments}
                    />
                    {/* Settings */}
                    <Route path="/settings" component={SettingsList} />
                    {/* Fallback */}
                    <Route path="/" component={() => <Redirect to="/events" />} />
                    <Route component={() => <Redirect to="/events" />} />
                  </Switch>
                </div>
              </Content>
            </Layout>

          </div>
        </Layout>
      </div>
    </Layout>
  );
};

export default App;
