import { gql } from 'apollo-boost';

const MODULE_DUPLICATE = gql`
    mutation moduleDuplicate($payload: ModuleDuplicateInput!){
        duplicateModule(payload: $payload) {
            id
        }
    }
`;

export default MODULE_DUPLICATE;
