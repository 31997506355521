import { gql } from 'apollo-boost';
import useApiQuery from '../useApiQuery';

export const GET_MODULE = gql`
    query module($module_id: Int!){
        module(module_id: $module_id) {
            id
            type
            image
            title
            subtitle
            subtitle_ended
            subtitle_not_started
            subtitle_results
            vote_limit
            subtype
            is_paid
            price
            megavote_amount
            megavote_price
            show_results
            show_all_comments
            show_new_comments
            show_own_comments
            show_total_count
            collect_contact_details
            contact_cta_text
            cta_text
            header_text
            banner_image
            published
            status
            moodmeter_labels
            options {
                id
                title
                subtitle
                image
                weight
                published
            }
            session: episode {
                id
                name
                event: show {
                    id
                    name
                }
            }
            created_at
            updated_at
        }
    }
`;

export default function useGetModule(initialModuleId) {
  return useApiQuery(
    GET_MODULE,
    'module',
    [initialModuleId],
    (moduleId) => {
      if (!moduleId) return undefined;
      return { module_id: moduleId };
    },
  );
}
