import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import GET_ALL_COMMENTS from '../../../../graphql/queries/getAllComments';
import { useModuleUpdateQuery } from '../../hooks/useModuleUpdates';

const processThreads = (threads, type = 'comment') => {
  let parents = [];
  let children = [];
  if (type === 'comment') {
    [parents, children] = _.partition(threads, { parent_id: 0 });
  } else {
    parents = _.filter(threads, { parent_id: 0 });
  }

  if (type === 'comment') {
    return {
      parents: _.orderBy(parents, ['createdAt'], ['desc']),
      children: _.orderBy(children, ['createdAt'], ['asc']),
    };
  }

  // questions and answers ordering
  return {
    parents: _.orderBy(parents, ['total_upvotes', 'createdAt'], ['desc', 'desc']),
    children: _.orderBy(children, ['createdAt'], ['asc']),
  };
};
const combineThreads = ({ parents, children }, type = 'comment') => _.reduce(
  parents,
  (resultThreads, parentThread) => {
    if (parentThread.is_hidden) {
      return resultThreads;
    }

    resultThreads.push(parentThread);
    if (type === 'comment') {
      _.forEach(
        _.filter(children, { parent_id: parentThread.id }),
        (reply) => resultThreads.push(reply),
      );
    }
    return resultThreads;
  },
  [],
);
const useProcessedComments = (array, type = 'comment') => {
  const [result, setResult] = useState([]);
  useEffect(() => {
    if (!array) return;

    setResult(
      combineThreads(
        processThreads(array, type),
        type,
      ),
    );
  }, [array]);

  return result;
};

// eslint-disable-next-line import/prefer-default-export
export const useThreads = (moduleId, type = 'comment') => {
  const order = useMemo(() => {
    switch (type) {
      case 'questions_answers':
        return 'total_upvotes';
      default:
        return 'created_at';
    }
  }, [type]);
  const [data, { loading }] = useModuleUpdateQuery(
    moduleId,
    GET_ALL_COMMENTS,
    useMemo(
      () => ({ module_id: moduleId, order }),
      [moduleId, order],
    ),
  );

  const comments = useMemo(() => {
    if (!data) return [];
    if (!data.allComments) return [];
    if (!data.allComments.comments) return [];
    return data.allComments.comments;
  }, [data]);
  const threads = useProcessedComments(comments, type);

  return { data: threads, loading };
};
