import { gql } from 'apollo-boost';

const OPTION_DELETE = gql`
    mutation optionDelete($option_id: Int!){
        deleteOption(option_id: $option_id) {
            id
        }
    }
`;

export default OPTION_DELETE;
