import { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { RootContext } from '../context/root-context';
import VALIDATE from '../graphql/mutations/validate';

const useLogin = () => {
  const [state, setState] = useContext(RootContext);
  const [validateToken] = useMutation(VALIDATE);

  const login = (user) => {
    if (user.token) {
      localStorage.setItem('token', user.token);
    }

    setState((currentState) => ({
      ...currentState,
      user,
    }));
  };

  const validate = async (token) => {
    const result = await validateToken({
      variables: { token },
    });

    if (result.data) {
      return result.data.isValid;
    }

    return false;
  };

  const logout = () => {
    setState((currentState) => ({
      ...currentState,
      user: false,
    }));

    localStorage.removeItem('token');
  };

  return {
    user: state.user,
    isModerator: state.user && state.user.role === 'moderator',
    login,
    logout,
    validate,
  };
};

export default useLogin;
