import { gql } from 'apollo-boost';

const DELETE_EVENT = gql`
    mutation deleteEvent($event_id: Int!){
        event: deleteShow(show_id: $event_id) {
            id
            name
        }
    }
`;

export default DELETE_EVENT;
