import { gql } from 'apollo-boost';

const CLIENT_DETAIL = gql`
    query client($client_id: Int!){
        client(client_id: $client_id) {
            id
            company_name
            address
            registration
            vat
            website
            active
            api_key
            whitelabel
        }
    }
`;

export default CLIENT_DETAIL;
