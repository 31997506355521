import { gql } from 'apollo-boost';

const OPTION_UPDATE = gql`
    mutation optionUpdate($option_id: Int!, $payload: OptionUpdateInput!){
        updateOption(option_id: $option_id, payload: $payload) {
            id
        }
    }
`;

export default OPTION_UPDATE;
