const useFormValues = (form, fields = []) => {
  if (!form) return {};
  if (!fields) return {};
  if (!fields.length) return {};

  const { getFieldValue } = form;
  return fields.reduce((result, field) => {
    const value = getFieldValue(field);
    return { ...result, [field]: value };
  }, {});
};

export default useFormValues;
