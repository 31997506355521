import React, { useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { ModuleType } from '../../../enums/module.enums';
import VOTE_RESULTS from '../../../graphql/queries/voteResults';
import VOTE_DETAILED_RESULTS from '../../../graphql/queries/voteDetailedResults';
import API_KEY_BY_MODULE from '../../../graphql/queries/apiKeyByModule';
import GET_MODULE from '../../../graphql/queries/moduleDetail';
import BreadCrumbs from '../../../components/Breadcrumbs';

const createColumns = ({ title, type }) => {
  if (type === ModuleType.Moodmeter) {
    return [{
      title: 'Average',
      dataIndex: 'result',
    }];
  }

  if (type === ModuleType.WordCloud) {
    return [{
      title: `Word - ${title}`,
      dataIndex: 'title',
    }, {
      title: 'Count',
      dataIndex: 'totalCount',
    }];
  }

  return [{
    title: 'Option',
    dataIndex: 'title',
  }, {
    title: 'Vote Count',
    dataIndex: 'singleCount',
  }, {
    title: 'Vote Weight',
    dataIndex: 'singleWeight',
  }, {
    title: 'Megavote Count',
    dataIndex: 'megavoteCount',
  }, {
    title: 'Megavote Weight',
    dataIndex: 'megavoteWeight',
  }, {
    title: 'Total Count',
    dataIndex: 'totalCount',
  }, {
    title: 'Total Weight',
    dataIndex: 'totalWeight',
  }];
};

const ModuleVoteResultsPage = ({ match }) => {
  const module_id = parseInt(match.params.module_id, 10);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { data: detailedData } = useQuery(VOTE_DETAILED_RESULTS, {
    variables: { module_id },
  });

  const { data: moduleData } = useQuery(GET_MODULE, {
    variables: { module_id },
  });

  const { data: api_data } = useQuery(API_KEY_BY_MODULE, {
    variables: { module_id },
  });

  const { loading, error, data } = useQuery(VOTE_RESULTS, {
    variables: { module_id },
    pollInterval: 10000,
  });

  const detailedParsedData = useMemo(() => {
    if (!moduleData?.module) return [];
    if (!detailedData?.detailedVotes) return [];

    return detailedData.detailedVotes.reduce((results, vote) => {
      const options = moduleData.module.options
        .filter((opt) => opt.id === vote.answer_id);

      if (!options.length) return results;

      return [...results, {
        tracking_id: vote.tracking_id,
        option: options[0].title,
        createdAt: vote.createdAt,
        contactName: vote.contact_name,
        contactEmail: vote.contact_email,
        newsletter: vote.subscribe_to_newsletter ? 'Yes' : 'No',
      }];
    }, []);
  }, [moduleData?.module, detailedData?.detailedVotes]);
  const isLoadingDetails = useMemo(() => {
    if (!moduleData?.module) return true;
    if (!detailedData?.detailedVotes) return true;
    return false;
  }, [moduleData?.module, detailedData?.detailedVotes]);
  const xmlUrl = useMemo(() => {
    if (!api_data?.module?.session?.event?.client?.api_key) return false;
    const { api_key } = api_data.module.session.event.client;

    return `${process.env.REACT_APP_REST_URL}/vote/results/${module_id}/${api_key}`;
  }, [module_id, api_data?.module?.session?.event?.client]);

  // TODO: Refactor breadcrumbs logic into a custom hook
  const breadcrumbs = useMemo(() => {
    if (!moduleData?.module?.session?.event) return [];

    const { module } = moduleData;
    const { session } = module;
    const { event } = session;
    return [
      {
        breadcrumbName: event.name,
        path: `/events/${event.id}`,
      },
      {
        breadcrumbName: session.name,
        path: `/events/${event.id}/sessions/${session.id}`,
      },
      {
        breadcrumbName: module.title,
        path: `/events/${event.id}/sessions/${session.id}/modules/${module.id}`,
      },
      { breadcrumbName: 'Results' },
    ];
  }, [moduleData?.module?.session?.event]);

  const structuredTable = useMemo(() => {
    if (!data || !moduleData || !data.voteResults || !moduleData.module) {
      return null;
    }

    const tableData = [];

    let index = 1;
    data.voteResults.options.forEach((result) => {
      const formattedData = {
        key: index,
        ...result,
        title: result.option.title,
      };

      // remove the typename (for CSV purposes)
      // eslint-disable-next-line no-underscore-dangle
      delete formattedData.__typename;
      delete formattedData.option;

      tableData.push(formattedData);
      index++;
    });

    const total = {
      key: index,
      ...data.voteResults,
      title: 'TOTAL',
    };

    // we don't need the options array here
    delete total.options;

    // remove the typename (for CSV purposes)
    // eslint-disable-next-line no-underscore-dangle
    delete total.__typename;
    tableData.push(total);

    const columns = createColumns(moduleData.module);

    return {
      columns,
      tableData,
    };
  }, [data, moduleData]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error</div>;
  if (!structuredTable) return null;

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      <header className="page-header">
        <h2>Results</h2>
        {xmlUrl && (
          <a href={xmlUrl} target="_blank" rel="noopener noreferrer">See results as XML feed</a>
        )}
        <div>
          <CSVLink
            data={structuredTable.tableData}
            filename="voteResults.csv"
            className="btn btn-primary ant-btn ant-btn-primary ant-btn-lg"
            target="_blank"
          >
            Results (CSV)
          </CSVLink>
          &nbsp;
          {isLoadingDetails ? 'Loading...' : (
            <CSVLink
              data={detailedParsedData}
              filename="detailedResults.csv"
              className="btn btn-primary ant-btn ant-btn-primary ant-btn-lg"
              target="_blank"
            >
              Details (CSV)
            </CSVLink>
          )}
        </div>
      </header>

      {data && data.voteResults && (
        <Table
          columns={structuredTable.columns}
          dataSource={structuredTable.tableData}
          pagination={{
            current: currentPage,
            pageSize: currentPageSize,
            onChange(page, pageSize) {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
            onShowSizeChange(page, pageSize) {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
        />
      )}
    </div>
  );
};

export default ModuleVoteResultsPage;
