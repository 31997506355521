import { Alert } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import * as Styled from './ImageCropDialog.styled';

const ImageCropDialog = ({ visible, title, information, ratio, upload, onComplete }) => {
  const inputRef = useRef();
  const [imageFile, setImageFile] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [crop, setCrop] = useState({});

  const [ratioX, ratioY] = ratio || [];

  const handleCancel = () => onComplete(false);

  const handleImageChange = useCallback(() => {
    const [file] = inputRef.current.files;
    setImageFile(file);
  }, [setImageFile]);

  useEffect(() => {
    if (!imageFile) return () => null;

    const objectUrl = URL.createObjectURL(imageFile);
    const img = document.createElement('img');
    img.setAttribute('src', objectUrl);
    img.style.visibility = 'hidden';
    img.style.position = 'absolute';
    document.body.append(img);
    // FIXME: I am very aware this is hacky
    let ignore = false;
    setTimeout(() => {
      if (ignore) return;
      const width = img.clientWidth;
      const height = img.clientHeight;
      setCrop(() => {
        if (Number.isNaN(ratioX / ratioY)) {
          return { unit: '%', height: 100, width: 100 };
        }

        if (ratioX > ratioY) {
          return makeAspectCrop(
            { unit: '%', height: 100 },
            ratioX / ratioY,
            width, height,
          );
        }

        if (ratioX < ratioY) {
          return makeAspectCrop(
            { unit: '%', height: 100 },
            ratioX / ratioY,
            width, height,
          );
        }

        return makeAspectCrop(
          { unit: '%', width: 100, height: 100 },
          ratioX / ratioY,
          width, height,
        );
      });
      setImagePreview(objectUrl);
    }, 100);

    return () => {
      URL.revokeObjectURL(objectUrl);
      ignore = true;
    };
  }, [imageFile, setImagePreview, setCrop]);

  const handleComplete = useCallback(async () => {
    const result = await upload(imageFile, crop);
    onComplete(result);
  }, [upload, imageFile, crop, onComplete]);

  return (
    <Styled.Container title={title} visible={visible} onOk={handleComplete} onCancel={handleCancel}>
      {imagePreview ? (
        <Styled.Preview>
          <Cropper
            onChange={(_, percentages) => setCrop(percentages)}
            onComplete={(_, percentages) => setCrop(percentages)}
            crop={crop}
            aspect={Number.isNaN(ratioX * ratioY) ? undefined : ratioX / ratioY}
            keepSelection
          >
            <Styled.PreviewImage src={imagePreview} alt="" />
          </Cropper>
        </Styled.Preview>
      ) : (
        <>
          {information && (
            <Alert
              type="info"
              style={{ marginBottom: 16 }}
              message={<span style={{ whiteSpace: 'pre-wrap' }}>{information}</span>}
            />
          )}
          <Styled.Content>
            <input ref={inputRef} type="file" onChange={handleImageChange} />
          </Styled.Content>
        </>
      )}
    </Styled.Container>
  );
};
export default ImageCropDialog;
