import { gql } from 'apollo-boost';

const VALIDATE = gql`
    mutation validateToken($token: String!){
        isValid: validate(token: $token) {
            username
            fullname
            role
            client_id
            id
        }
    }
`;

export default VALIDATE;
