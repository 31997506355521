import { useMutation } from '@apollo/react-hooks';
import { useCallback } from 'react';
import { UPDATE_EVENT } from '../../graphql/events';

export default function useUpdateEvent() {
  const [fetch] = useMutation(UPDATE_EVENT);

  const updateEvent = useCallback(
    (event_id, payload) => fetch({
      variables: { event_id, payload },
    }),
    [fetch],
  );

  return [updateEvent];
}
