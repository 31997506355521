import React from 'react';

import { PresenterProvider } from '../../providers/PresenterProvider';
import Presenter from './Presenter';

export default function PresenterPage() {
  return (
    <PresenterProvider>
      <Presenter />
    </PresenterProvider>
  );
}
