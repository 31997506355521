import { gql } from 'apollo-boost';

const VOTE_RESULTS = gql`
    query voteResults($module_id: Int!){
        voteResults(module_id:$module_id){
            singleCount
            singleWeight
            megavoteCount
            megavoteWeight
            totalCount
            totalWeight
            options {
                id
                option {
                    title
                    subtitle
                    published
                }
                singleCount
                singleWeight
                megavoteCount
                megavoteWeight
                totalCount
                totalWeight
            }
        }
    }
`;

export default VOTE_RESULTS;
