import styled from 'styled-components/macro';

export const Idle = styled.div`
  display: flex;
  height: 32px;
  padding: 0 16px;
  border: 0 solid #999999;
  color: #999999;
  background: transparent;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;
export const Saving = styled(Idle)`
  border-width: 1px;
  border-color: #00792e;
  color: #00792e;
`;
export const Saved = styled(Idle)`
  border-width: 0;
  border-color: transparent;
  background-color: #00792e;
  color: white;
`;
