import { gql } from 'apollo-boost';

const UPDATE_EVENT = gql`
    mutation updateEvent($event_id: Int!, $payload: ShowUpdateInput!){
        event: updateShow(show_id: $event_id, payload: $payload) {
            id
            name
        }
    }
`;

export default UPDATE_EVENT;
