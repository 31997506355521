import { useQuery } from '@apollo/react-hooks';
import { Col, Form, Input, message, Row, Select, Switch } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Textarea from 'react-textarea-autosize';
import styled from 'styled-components/macro';
import LIST_EVENTS from '../../graphql/queries/listEvents';
import useLogin from '../../hooks/useLogin';
import useAutoSavingForm from '../../hooks/useAutoSavingForm';

import ImageUploadButton from '../ImageUpload/ImageUploadButton';
import { RATIO_PORTRAIT } from '../ImageUpload/ImageUploadRatio';
import LocalizedTooltipBubble from '../common/TooltipBubble/TooltipBubble';
import PageControls from '../common/PageControls';
import PageHeading from '../common/PageHeading';
import AutosaveStatus from '../AutosaveStatus/AutosaveStatus';
import usePresenterUrl from '../../hooks/presenter/usePresenterUrl';
import { useEvent } from '../../hooks/providers/events';

const PageHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const SessionForm = (props) => {
  const [event] = useEvent();
  const eventId = useMemo(() => event?.id, [event]);
  const { type, form, onSubmit, error, session = {}, sessionCreated } = props;
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [image, setImage] = useState(session.image || undefined);
  const presenterUrl = usePresenterUrl();
  const name = session.name || '';
  const description = session.description || '';
  const display_info = session.display_info || false;
  const { user } = useLogin();
  const { Option } = Select;
  const {
    error: showListError,
    loading: showListLoading,
    data: eventsData,
  } = useQuery(LIST_EVENTS, {
    variables: { client_id: user.client_id },
    skip: !user.client_id,
  });

  // previous false || true would always return true
  // We need to use local state in order to know, whether to display terms_location field
  const [showTerms, setShowTerms] = useState(
    session.show_terms === true,
  );

  const [termsLocation, setTermsLocation] = useState(
    session.terms_location !== undefined
      ? session.terms_location
      : 'welcome',
  );

  // conditional state setting can be like this here, because right side is false
  const isLive = session.is_live || false;

  const { t } = useTranslation();
  const [modalShown, setModalShown] = useState(false);
  useEffect(() => {
    if (modalShown) return;
    if (!sessionCreated) return;

    message.success(`Successfully created session: ${session.name}`);
    setModalShown(true);
  }, [modalShown, sessionCreated, session, setModalShown]);
  useEffect(() => {
    if (!error) return;
    // eslint-disable-next-line no-console
    console.error(error);
  }, [error]);
  const handleFormSubmit = useCallback(async (e) => {
    if (e) e.preventDefault();
    try {
      const payload = await validateFields();
      onSubmit(payload);
    } catch (exception) {
      message.error('Form submission failed. Check below for details');
    }
  }, [validateFields, onSubmit]);
  const [autosaveProps, triggerAutosave, autosaveStatus] = useAutoSavingForm(handleFormSubmit, {
    delay: 300,
    enabled: type === 'update',
  });

  const identityMapper = (value) => value;
  const fieldChangeHandler = (field, mapper = (e) => e.target.value) => (e) => {
    const value = mapper(e);
    switch (field) {
      case 'show_terms':
        setShowTerms(value);
        break;
      case 'terms_location':
        setTermsLocation(value);
        break;
      case 'image':
        setFieldsValue({ image: value });
        setImage(value);
        break;
      default:
        break;
    }

    triggerAutosave();
  };

  if (error || showListError) {
    return 'Error...';
  }
  if (showListLoading) {
    return 'Loading...';
  }

  return (
    <div>
      <PageHeader>
        <PageHeading title="session" type={type} name={session.name} />
        <AutosaveStatus status={autosaveStatus} />
        <PageControls
          type={type}
          localizedButtons="sessions.buttons"
          presenterUrl={presenterUrl}
          onClickCreate={handleFormSubmit}
        />
      </PageHeader>
      <Form
        onSubmit={handleFormSubmit}
        className="episode-form"
        {...autosaveProps}
      >
        <Row type="flex" gutter={16} justify="space-between">
          <Col md={20} xs={24}>
            <Col span={16}>
              <Form.Item label={t('sessions.form.name.label')}>
                {getFieldDecorator('name', {
                  rules: [{ required: true, message: t('sessions.form.name.errors.required') }],
                  initialValue: name,
                  onChange: fieldChangeHandler('name'),
                })(<Input placeholder={t('sessions.form.name.placeholder')} />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t('sessions.form.event.label')}>
                {/* TODO: Refactor show_id -> event_id */}
                {getFieldDecorator('show_id', {
                  onChange: fieldChangeHandler('show_id', identityMapper),
                  rules: [{ required: true, message: t('sessions.form.event.errors.required') }],
                  initialValue: eventId ?? session?.event?.id ?? undefined,
                })(
                  <Select placeholder={t('sessions.form.event.placeholder')}>
                    {eventsData.events.map((e) => (
                      <Option key={e.id} value={e.id}>{e.name}</Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('sessions.form.description.label')} style={{ margin: 0 }}>
                {getFieldDecorator('description', {
                  onChange: fieldChangeHandler('description'),
                  initialValue: description,
                })(
                  <Textarea
                    style={{ width: '100%', padding: 12, resize: 'none', lineHeight: 1.7 }}
                    minRows={2}
                    maxRows={3}
                    placeholder={t('sessions.form.description.placeholder')}
                  />,
                )}
              </Form.Item>
            </Col>
          </Col>
        </Row>
        <Row type="flex" gutter={16} justify="space-between" style={{ marginTop: '1em' }}>
          <Col md={20} xs={24}>
            <Col md={10} xs={24}>
              <Form.Item>
                {getFieldDecorator('is_live', {
                  onChange: fieldChangeHandler('is_live', identityMapper),
                  valuePropName: 'checked',
                  initialValue: isLive,
                })((
                  <Switch />
                ))}
                <span style={{ margin: '0 8px 0 4px' }}>
                  {t('sessions.form.is_live.label')}
                </span>
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('show_terms', {
                  onChange: fieldChangeHandler('show_terms', identityMapper),
                  valuePropName: 'checked',
                  initialValue: showTerms,
                })(
                  <Switch />,
                )}
                <span style={{ margin: '0 8px 0 4px' }}>{t('sessions.form.show_terms.label')}</span>
                <LocalizedTooltipBubble color="blue" contentKey="sessions.form.show_terms.tooltip" />
              </Form.Item>
            </Col>
            <Col md={2} xs={6}>
              <Row type="flex" justify="end">
                {showTerms && termsLocation === 'welcome' && (
                  <>
                    <ImageUploadButton
                      value={image}
                      title={t('sessions.form.image.label')}
                      information={t('sessions.form.image.tooltip')}
                      ratio={RATIO_PORTRAIT}
                      onChange={fieldChangeHandler('image', identityMapper)}
                    />
                    {getFieldDecorator('image', { initialValue: image })(
                      <Input type="hidden" />,
                    )}
                  </>
                )}
              </Row>
            </Col>
            <Col md={10} xs={12}>
              <Form.Item>
                {getFieldDecorator('display_info', {
                  onChange: fieldChangeHandler('display_info', identityMapper),
                  valuePropName: 'checked',
                  initialValue: display_info,
                })(
                  <Switch />,
                )}
                <span style={{ margin: '0 8px 0 4px' }}>
                  {t('sessions.form.display_info.label')}
                </span>
                <LocalizedTooltipBubble color="blue" contentKey="sessions.form.display_info.tooltip" />
              </Form.Item>
              {showTerms && (
                <Form.Item label={t('sessions.form.terms_location.label')}>
                  {getFieldDecorator('terms_location', {
                    onChange: fieldChangeHandler('terms_location', identityMapper),
                    initialValue: termsLocation,
                  })(
                    <Select placeholder={t('sessions.form.terms_location.placeholder')}>
                      {['welcome', 'before_purchase', 'before_voting'].map((location) => (
                        <Option key={location} value={location}>
                          {t(`sessions.form.terms_location.values.${location}`)}
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Form.Item>
              )}
            </Col>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(SessionForm);
