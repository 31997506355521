import * as Clients from './clients';
import * as Sessions from './sessions';
import * as Events from './events';
import * as Modules from './modules';

export const ClientPages = {
  List: Clients.ClientsListPage,
  Create: Clients.CreateClientPage,
  Update: Clients.UpdateClientPage,
};

export const SessionPages = {
  List: Sessions.ListSessionsPage,
  Create: Sessions.CreateSessionPage,
  Update: Sessions.UpdateSessionPage,
};

export const EventPages = {
  List: Events.EventsListPage,
  Create: Events.CreateEventPage,
  Update: Events.UpdateEventPage,
};

export const ModulePages = {
  Create: Modules.CreateModulePage,
  Update: Modules.UpdateModulePage,
  VoteResults: Modules.Results.VoteResults,
  MeterResults: Modules.Results.MeterResults,
  ClickResults: Modules.Results.ClickResults,
  Comments: Modules.Results.Comments,
};
