import { gql } from 'apollo-boost';

const USER_DELETE = gql`
    mutation userDelete($user_id: Int!){
        deleteUser(user_id: $user_id) {
            id
        }
    }
`;

export default USER_DELETE;
