import { gql } from 'apollo-boost';

const USER_ADD = gql`
    mutation userCreate($payload: UserCreateInput!){
        createUser(payload: $payload) {
            id
        }
    }
`;

export default USER_ADD;
