import React from 'react';
import { Menu, Dropdown, Icon, Button } from 'antd';

import useLogin from '../../../hooks/useLogin';

const UserMenu = () => {
  const { user, logout } = useLogin();
  const menu = (
    <Menu>
      <Menu.Item key="3">
        <Button block className="usermenu-button" type="link" onClick={() => logout()}>Logout</Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      getPopupContainer={() => document.getElementById('user-menu')}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="ant-dropdown-link" href="#">
        {user.fullname} <Icon type="down" /> <br />
        <span className="user-title">{user.role}</span>
      </a>
    </Dropdown>
    /* <div className="menu">
      <button type="button" onClick={() => setIsVisible(!isVisible)}>
        <span>
          {isVisible ? <i className="fa fa-caret-down" /> : <i className="fa fa-caret-right" />}
        </span>
      </button>

      {isVisible && (
        <div className="menu-items">
          <button type="button"> Profile Settings</button>
          <button type="button"> Change Password</button>
          <button type="button" onClick={() => logout()}> Logout</button>
        </div>
      )}
    </div> */
  );
};

export default UserMenu;
