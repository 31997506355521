import React from 'react';
import useLogin from '../../../hooks/useLogin';
import * as Styled from './PageHeading.styled';

export default function PageHeading({ title, name, type = 'create' }) {
  const { isModerator } = useLogin();

  if (type === 'create') {
    return <Styled.PageHeading>Create {title}</Styled.PageHeading>;
  }

  if (!isModerator) {
    return <Styled.PageHeading>Update {title}: {name}</Styled.PageHeading>;
  }

  return <Styled.PageHeading>{name}</Styled.PageHeading>;
}
