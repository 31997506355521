import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from '@apollo/react-hooks';

import './i18n';

import './assets/styles/styles.scss';
import App from './containers/App';
import Login from './containers/Login';
import RootProvider from './context/root-context';
import PrivateRoute from './components/PrivateRoute';
import PresenterPage from './pages/presenter';
import { Routes } from './routes';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL });

const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('token');

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <RootProvider>
      <BrowserRouter>
        <LastLocationProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path={Routes.Presenter.ofModule} component={PresenterPage} />
            <PrivateRoute path={Routes.Presenter.ofSession} component={PresenterPage} />
            <PrivateRoute path={Routes.Presenter.ofEvent} component={PresenterPage} />
            {/** App */}
            <PrivateRoute path="/" component={App} />
          </Switch>
        </LastLocationProvider>
      </BrowserRouter>
    </RootProvider>
  </ApolloProvider>,
  document.getElementById('root'),
);
