import { Icon } from 'antd';
import styled from 'styled-components/macro';

export const PageHeader = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
export const PageHeading = styled.h1`
  margin-bottom: 0;
`;
export const PresenterButtonIcon = styled(Icon)`
  font-size: 1rem;
`;
export const Label = styled.span`
  margin: 0 4px;
`;
