import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { message } from 'antd';

import SessionForm from '../../components/SessionForm/SessionForm';
import ModulesList from '../../components/ModulesList/ModulesList';
import BreadCrumbs from '../../components/Breadcrumbs';

import UPDATE_SESSION from '../../graphql/mutations/updateSession';
import SESSIONS_BY_CLIENT from '../../graphql/queries/listSessionsByClient';

import useLogin from '../../hooks/useLogin';
import { EventProvider } from '../../providers/EventProvider';
import { SessionProvider } from '../../providers/SessionProvider';
import { useMappedParams } from '../../hooks/utils';
import { useSession } from '../../hooks/providers/sessions';
import { useEvent } from '../../hooks/providers/events';
import { RouteBuilders } from '../../routes';

function UpdateSessionPage() {
  const { eventId, sessionId } = useMappedParams({
    eventId: (params) => +params.event_id,
    sessionId: (params) => +params.session_id,
  });
  return (
    <EventProvider eventId={eventId} refetch={false}>
      <SessionProvider sessionId={sessionId} refetch={false}>
        <UpdateSessionWrapper />
      </SessionProvider>
    </EventProvider>
  );
}

const UpdateSessionWrapper = () => {
  // local state variables
  const [apiError, setApiError] = useState('');
  const { user, isModerator } = useLogin();

  // TODO: Extract queries to hooks
  // graphql
  const [event] = useEvent();
  const [session, { error, load: loadSession }] = useSession();
  const [updateSession] = useMutation(UPDATE_SESSION, {
    refetchQueries: [
      { query: SESSIONS_BY_CLIENT, variables: { client_id: user.client_id } },
    ],
  });
  const breadcrumbs = useMemo(() => {
    if (!event) {
      return [];
    }

    if (!session) {
      return [
        {
          breadcrumbName: event.name,
          path: RouteBuilders.ofEvent({ eventId: event.id }),
        },
      ];
    }

    return [
      {
        breadcrumbName: event.name,
        path: `/events/${event.id}`,
      },
      {
        breadcrumbName: session.name,
        path: false,
      },
    ];
  }, [event, session]);

  const handleSubmit = useCallback(async (payload) => {
    try {
      const result = await updateSession({
        variables: { session_id: session.id, payload },
      });
      if (!result) return;

      message.success('Session saved');
      await loadSession();
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        setApiError(err.graphQLErrors[0].message);
      } else {
        setApiError('Unknown error occurred');
      }
    }
  }, [session, updateSession, setApiError]);

  const location = useLocation();
  const sessionCreated = useMemo(
    () => location.state && location.state.referrer === 'successful-create',
    [location.state],
  );
  if (error) return `Error! ${error.message}`;

  if (!event || !session) return null;

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      {!isModerator && (
        <SessionForm
          type="update"
          isModerator={isModerator}
          onSubmit={handleSubmit}
          session={session}
          error={apiError}
          sessionCreated={sessionCreated}
        />
      )}
      <ModulesList />
    </div>
  );
};

export default UpdateSessionPage;
