import React, { useMemo } from 'react';
import ReactWordCloud from 'react-wordcloud';
import styled from 'styled-components/macro';

const WordCloudResultsContainer = styled.div`
  margin: 0;
  width: 100%;
  height: calc(100% - 100px);
`;

const WordCloudResults = ({ data, primaryColor, secondaryColor }) => {
  const colors = useMemo(
    () => [
      `#${primaryColor}`,
      `#${secondaryColor}`,
    ],
    [primaryColor, secondaryColor],
  );

  if (!data) return null;

  return (
    <WordCloudResultsContainer>
      <ReactWordCloud
        words={data}
        maxWords={25}
        options={{
          enableOptimizations: false,
          enableTooltip: false,
          deterministic: true,
          transitionDuration: 250,
          spiral: 'rectangular',
          randomSeed: 'votemo',
          scale: 'log',
          rotations: 0,
          padding: 1,
          fontSizes: [32, 72],
          fontFamily: 'Montserrat',
          fontWeight: 'bold',
          colors,
        }}
      />
    </WordCloudResultsContainer>
  );
};

export default WordCloudResults;
