import { useMutation } from '@apollo/react-hooks';
import { Icon } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import imgModerator from '../../../assets/img/moderator.png';
import COMMENT_UPDATE from '../../../graphql/mutations/commentUpdate';
import * as Styled from './NewComment.styled';

const NewComment = ({
  comment,
  onReply,
  moderatorColor,
  showControls = true,
  isReply = false,
  showReplies = false,
}) => {
  const { id, createdAt, author, text, is_hidden, is_moderated } = comment;
  const { t } = useTranslation();

  const [updateComment] = useMutation(COMMENT_UPDATE, {
    refetchQueries: ['allComments'],
  });

  const updateHidden = useCallback(async () => {
    try {
      await updateComment({
        variables: {
          comment_id: id,
          payload: { is_hidden: !is_hidden },
        },
      });
    } catch (e) {
      // deal with error
    }
  }, [id, is_hidden]);

  const time = moment.duration(moment().diff(moment(createdAt))).as('minutes');
  return (
    <>
      <Styled.Comment isReply={isReply} isHidden={is_hidden}>
        <Styled.CommentHeader>
          <Styled.CommentAuthor moderatorColor={is_moderated ? moderatorColor : undefined}>
            <span className="author-name">{author}</span>
            {is_moderated && (
              <span className="moderator-img">
                <img src={imgModerator} alt="Moderated comment" />
              </span>
            )}
          </Styled.CommentAuthor>
          <Styled.CommentTimestamp isHidden={is_hidden}>
            {moment().subtract(time, 'minutes').fromNow()}
          </Styled.CommentTimestamp>
          {showControls && (
            <Styled.CommentControls>
              <div className="controls">
                {showReplies && !isReply && (
                  <button type="button" onClick={() => onReply(comment)}>
                    Reply
                  </button>
                )}
                <button type="button" onClick={updateHidden}>
                  {is_hidden ? t('modules.buttons.button_restore') : t('modules.buttons.button_hide')}
                </button>
              </div>
              <div className="upvotes">
                <Icon type="like" />
                <span>{comment.total_upvotes}</span>
              </div>
            </Styled.CommentControls>
          )}
        </Styled.CommentHeader>
        <Styled.CommentContent>{text}</Styled.CommentContent>
      </Styled.Comment>
      {showReplies && comment.replies && comment.replies.length > 0 && (
        comment.replies.map((reply) => (
          <NewComment
            comment={reply}
            showControls={showControls}
            moderatorColor={moderatorColor}
            isReply
          />
        ))
      )}
    </>
  );
};

export default NewComment;
