import { gql } from 'apollo-boost';

const COMMENT_UPDATE = gql`
    mutation commentUpdate($comment_id: Int!, $payload: CommentUpdateInput!){
        updateComment(comment_id: $comment_id, payload: $payload) {
            id
        }
    }
`;

export default COMMENT_UPDATE;
