import { gql } from 'apollo-boost';
import useApiQuery from '../useApiQuery';

export const GET_SESSION = gql`
    query session($session_id: Int!){
        session: episode(episode_id: $session_id) {
            id
            name
            description
            display_info
            image
            date_live
            show_terms
            terms_location
            is_live
            event: show {
                id
                name
                client {
                    id
                }
            }
            modules {
                id
                title
                type
                subtype
                subtitle
                subtitle_not_started
                subtitle_ended
                published
                weight
                status
            }
            created_at
            updated_at
        }
    }
`;

export default function useGetSession(initialSessionId) {
    return useApiQuery(
      GET_SESSION,
      'session',
      [initialSessionId],
      (sessionId) => {
          if (!sessionId) return undefined;
          return { session_id: sessionId };
      },
    );
}
