import { useCallback, useMemo } from 'react';
import { useEventContext } from '../../providers/EventProvider';

export function useEvent() {
  const { event, loading, load } = useEventContext();
  return [event, { loading, load }];
}

// TODO: Refactor filters
export function useEventSessions(live = false) {
  const [event, { loading, load }] = useEvent();
  const sessions = useMemo(() => {
    if (!event) return [];
    if (!live) return event.sessions;
    return event.sessions.filter((session) => session.is_live);
  }, [event, live]);
  const reloadSessions = useCallback(() => {
    if (!event) return;
    load(event.id);
  }, [event, load]);

  return [sessions, { loading, reloadSessions }];
}
