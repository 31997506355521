import { Card, Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import styled from 'styled-components/macro';
import useFormValues from '../useFormValues';
import LocalizedTooltipBubble from '../../../common/TooltipBubble/TooltipBubble';
import { useModuleFields } from '../../../ModuleForm/ModuleForm.hooks';

const Label = styled.span`margin: 0 12px;
  display: inline-flex;
  align-items: center`;

const OptionsCollapse = ({ module, form, onChange }) => {
  const { t } = useTranslation();
  const { getFieldDecorator, getFieldValue } = form;
  const moduleFields = useModuleFields(getFieldValue('type'));
  const { show_all_comments = false } = useFormValues(form, ['show_all_comments']);

  const visible = useMemo(
    () => [
      'show_results',
      'collect_contact_details',
      'is_paid',
      'show_all_comments',
      'vote_limit',
    ].some((key) => moduleFields[key]),
    [moduleFields],
  );

  if (!visible) return null;

  return (
    <Card style={{ marginBottom: 16 }}>
      {moduleFields.show_results && (
        <Row type="flex" gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('show_results', {
                valuePropName: 'checked',
                initialValue: module.show_results,
                onChange,
              })(<Switch />)}
              <Label>
                {t('modules.form.show_results.label')}
              </Label>
            </Form.Item>
          </Col>
          {moduleFields.show_total_count && getFieldValue('show_results') && (
            <Col span={24}>
              <Form.Item>
                {getFieldDecorator('show_total_count', {
                  valuePropName: 'checked',
                  initialValue: module.show_total_count,
                  onChange,
                })(<Switch />)}
                <Label>{t('modules.form.show_count.label')}</Label>
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      {moduleFields.collect_contact_details && (
        <Row type="flex" gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('collect_contact_details', {
                valuePropName: 'checked',
                initialValue: module.collect_contact_details,
                onChange,
              })(
                <Switch />,
              )}
              <Label>{t('modules.form.collect_leads.label')}</Label>
            </Form.Item>
          </Col>
          {getFieldValue('collect_contact_details') && (
            <Col span={24}>
              <Form.Item label={t('modules.form.cta.label')}>
                {getFieldDecorator('contact_cta_text', {
                  rules: [{ required: true, message: t('modules.form.cta.errors.required') }],
                  initialValue: module.contact_cta_text,
                  onChange,
                })(
                  <Input placeholder={t('modules.form.cta.placeholder')} />,
                )}
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      {moduleFields.is_paid && (
        <Row type="flex" gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('is_paid', {
                valuePropName: 'checked',
                initialValue: module.is_paid,
                onChange,
              })(<Switch />)}
              <Label>{t('modules.form.is_paid.label')}</Label>
            </Form.Item>
          </Col>
        </Row>
      )}
      {moduleFields.is_paid && getFieldValue('is_paid') && (
        <Row type="flex" gutter={16}>
          <Col span={24}>
            <Form.Item>
              <Label>{t('modules.form.price.label')}</Label>
              {getFieldDecorator('price', { initialValue: module.price, onChange })(
                <NumberFormat thousandSeparator prefix="€ " />,
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Label>{t('modules.form.megavote_price.label')}</Label>
              {getFieldDecorator('megavote_price', { initialValue: module.megavote_price, onChange })(
                <NumberFormat thousandSeparator prefix="€ " />,
              )}
            </Form.Item>
            <Form.Item>
              <Label>{t('modules.form.megavote_amount.label')}</Label>
              {getFieldDecorator('megavote_amount', { initialValue: module.megavote_amount, onChange })(
                <NumberFormat />,
              )}
            </Form.Item>
          </Col>
        </Row>
      )}
      {moduleFields.show_all_comments && (
        <Row type="flex" gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('show_all_comments', {
                valuePropName: 'checked',
                initialValue: module.show_all_comments,
                onChange,
              })(<Switch />)}
              <Label>{t('modules.form.show_all_comments.label')}</Label>
              <LocalizedTooltipBubble color="blue" contentKey="modules.form.show_all_comments.tooltip" compact />
            </Form.Item>
          </Col>
          {moduleFields.show_new_comments && show_all_comments && (
            <Col span={24}>
              <Form.Item>
                {getFieldDecorator('show_new_comments', {
                  valuePropName: 'checked',
                  initialValue: module.show_new_comments,
                  onChange,
                })(<Switch />)}
                <Label>{t('modules.form.show_new_comments.label')}</Label>
                <LocalizedTooltipBubble color="blue" contentKey="modules.form.show_new_comments.tooltip" compact />
              </Form.Item>
            </Col>
          )}
        </Row>
      )}
      {moduleFields.vote_limit && (
        <Row type="flex" gutter={16}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator('vote_limit', {
                initialValue: module.vote_limit || 0,
                onChange,
              })(<InputNumber precision={0} step={1} />)}
              <Label>{t('modules.form.vote_limit.label')}</Label>
              <LocalizedTooltipBubble color="blue" contentKey="modules.form.vote_limit.tooltip" />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default OptionsCollapse;
