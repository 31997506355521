import React, { useContext } from 'react';

const ValidatorContext = React.createContext({ valid: false, loading: false, redirect: false });
export const useValidatorContext = () => useContext(ValidatorContext);

export default function ValidatorProvider({ value, children }) {
  return (
    <ValidatorContext.Provider
      value={value}
      children={children}
    />
  );
}
