import { useCallback, useMemo } from 'react';

import { useModuleContext } from '../../providers/ModuleProvider';
import { useSession, useSessionModules } from './sessions';
import { useEvent } from './events';
import { RouteBuilders } from '../../routes';
import { isModulePresentable } from '../../enums/module.enums';

export function useModule() {
  const { module, loading, load } = useModuleContext();
  return [module, { loading, load }];
}

export function useAdjacentModules() {
  const [currentModule] = useModule();
  const [modules] = useSessionModules();

  return useMemo(() => {
    if (!currentModule) return {};
    const presentableModules = modules.filter(isModulePresentable);
    const currentIndex = presentableModules.findIndex(
      (module) => module.id === currentModule.id,
    );
    if (currentIndex < 0) return {};

    const previous = presentableModules[currentIndex - 1];
    const next = presentableModules[currentIndex + 1];
    return { previous, next };
  }, [currentModule, modules]);
}

export function useModuleOptions() {
  const [module] = useModule();

  return useMemo(() => {
    if (!module) return [];
    if (!module.options) return [];
    return module.options;
  }, [module]);
}

export function useModulePath() {
  const [event] = useEvent();
  const [session] = useSession();

  return useCallback((module) => {
    if (!event) return undefined;
    if (!session) return undefined;
    if (!module) return undefined;

    return RouteBuilders.Presenter.ofModule({
      eventId: event.id,
      sessionId: session.id,
      moduleId: module.id,
    });
  }, [event, session]);
}
