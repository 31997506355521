import { gql } from 'apollo-boost';

const SESSIONS_BY_CLIENT = gql`
    query sessionsByClient($client_id: Int!){
        sessions: episodesByClient(client_id: $client_id) {
            id
            name
            description
            is_live
            event: show {
                id
                name
            }
        }
    }
`;

export default SESSIONS_BY_CLIENT;
