import { Modal } from 'antd';
import styled from 'styled-components/macro';

export const Container = styled(Modal)``;
export const Restrictions = styled.div``;
export const Title = styled.div``;
export const Content = styled.div``;
export const Button = styled.button``;

export const Preview = styled.div`
  position: relative;
  width: 100%;
`;

export const PreviewImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
`;
