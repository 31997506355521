import React, { useState } from 'react';

export const RootContext = React.createContext([{}, () => {}]);

export default ({ children }) => {
  const [state, setState] = useState({});

  return (
    <RootContext.Provider value={[state, setState]}>
      {children}
    </RootContext.Provider>
  );
};
