import React, { useEffect, useMemo, useState } from 'react';

import ValidatorProvider from './validator-context';

import { useEvent, useModule, useSession } from '../../hooks/providers';
import { usePresenterParams } from '../hooks/usePresenterParams';
import { useCombinedState } from '../../hooks/utils/useCombinedState';

import { isModuleValid, isSessionValid } from './utils';

function useValidatorState() {
  return useCombinedState({
    loading: false,
    valid: false,
    result: { event: false, session: false, module: false },
  }, {
    loading: { loading: true },

    invalidEvent: {
      valid: false,
      result: { event: false, session: false, module: false },
    },
    invalidSession: ({ event }) => ({
      valid: false,
      result: { event: event.id, session: false, module: false },
    }),
    invalidModule: ({ event, session }) => ({
      valid: false,
      result: { event: event.id, session: session.id, module: false },
    }),

    valid: ({ event, session, module }) => ({
      valid: true,
      result: { event: event.id, session: session.id, module: module.id },
    }),
  });
}

function usePresenterValidator() {
  const { eventId, sessionId, moduleId } = usePresenterParams();
  const state = useValidatorState();

  const [event, { loading: eventLoading }] = useEvent();
  const [session, { loading: sessionLoading }] = useSession();
  const [module, { loading: moduleLoading }] = useModule();

  useEffect(() => {
    console.log({
      event, eventLoading,
      session, sessionLoading,
      module, moduleLoading,
    });
  }, [event, eventLoading, session, sessionLoading, module, moduleLoading]);

  return useMemo(() => {
    // check for loading
    if (eventLoading) return state.loading();
    if (!eventId) return state.invalidEvent();
    if (event === undefined) return state.loading();
    if (event === null) return state.invalidEvent();

    // validate event
    const hasLiveSessions = event.sessions.some((session) => session.is_live);
    if (!hasLiveSessions) return state.invalidEvent();

    // validate session
    if (sessionLoading) return state.loading();
    if (!sessionId) return state.invalidSession({ event });
    if (session === undefined) return state.loading();
    if (session === null) return state.invalidSession({ event });

    if (session.event.id !== event.id) return state.invalidSession({ event });
    if (!isSessionValid(session)) return state.invalidSession({ event });

    // validate module
    if (moduleLoading) return state.loading();
    if (!moduleId) return state.invalidModule({ event, session });
    if (module === undefined) return state.loading();
    if (module === null) return state.invalidModule({ event, session });

    if (module.session.id !== session.id) return state.invalidModule({ event, session });
    if (!isModuleValid(module)) return state.invalidModule({ event, session });

    return state.valid({ event, session, module });
  }, [
    eventLoading, sessionLoading, moduleLoading,
    eventId, sessionId, moduleId,
    event, session, module,
    state,
  ]);
}

export default function PresenterValidator({ children }) {
  const { loading, valid, result } = usePresenterValidator();

  const [validated, setValidated] = useState(valid);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    console.log({ loading, valid, result });
  }, [loading, valid, result]);

  useEffect(() => {
    if (loading) return;

    setValidated(valid);
  }, [validated, loading, valid, setValidated]);

  useEffect(() => {
    if (loaded) return;
    if (loading) return;

    setLoaded(true);
  }, [loaded, loading, setLoaded]);

  const value = useMemo(
    () => ({ valid: validated, loading: !loaded, result }),
    [validated, loaded, result],
  );

  return <ValidatorProvider value={value} children={children} />;
}

