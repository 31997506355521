import { useCallback, useEffect, useReducer } from 'react';

export const AnimationStatus = {
  Idle: 'IDLE',
  Running: 'RUNNING',
  Finished: 'FINISHED',
};

const animationAction = (type) => ({ type });
export const AnimationAction = {
  idle: () => animationAction(AnimationStatus.Idle),
  running: () => animationAction(AnimationStatus.Running),
  finished: () => animationAction(AnimationStatus.Finished),
};

const disposableTimeout = (handler, delay) => {
  const timeout = setTimeout(handler, delay);
  return () => {
    clearTimeout(timeout);
  };
};

export default function useAnimationState({ duration, enabled }) {
  const [state, dispatch] = useReducer(
    (prevState, action) => action.type ?? AnimationStatus.Idle,
    AnimationStatus.Idle,
  );

  // Updates the animation state
  useEffect(() => {
    if (!enabled) return;
    if (state !== AnimationStatus.Running) return () => {};

    // Finish playing the animation after duration when running
    return disposableTimeout(
      () => dispatch(AnimationAction.finished()),
      duration,
    );
  }, [state, dispatch, duration]);

  const startAnimation = useCallback(() => {
    if (!enabled) return;
    dispatch(AnimationAction.running());
  }, [dispatch]);

  return [state, startAnimation];
}
