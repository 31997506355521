import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import UserForm from './Form';
import USER_LIST from '../../graphql/queries/userList';
import USER_UPDATE from '../../graphql/mutations/userUpdate';

const UserUpdate = ({ match }) => {
  // local state variables
  const [redirect, setRedirect] = useState('');
  const [apiError, setApiError] = useState('');

  const user_id = parseInt(match.params.user_id, 10);

  // graphql
  const { loading, error, data } = useQuery(USER_LIST, {
    variables: { user_id },
  });

  const [updateUser] = useMutation(USER_UPDATE, {
    refetchQueries: ['userList'],
  });

  const onSubmit = async (payload) => {
    const updatedPayload = { ...payload };

    // TODO: fix this on server side
    if (updatedPayload.password === '') {
      delete updatedPayload.password;
    }

    try {
      const result = await updateUser({
        variables: {
          user_id,
          payload: updatedPayload,
        },
      });

      if (result) {
        setRedirect('/users');
      }
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        setApiError(err.graphQLErrors[0].message);
      } else {
        setApiError('Unknown error occured');
      }
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return (
    <div>
      <h1>Update user</h1>
      <UserForm onSubmit={onSubmit} user={data.users[0]} error={apiError} />
    </div>
  );
};

export default UserUpdate;
