import styled from 'styled-components/macro';
import React, { useMemo } from 'react';
import VOTE_RESULTS from '../../../../graphql/queries/voteResults';
import { useModuleUpdateQuery } from '../../hooks/useModuleUpdates';
import PresenterHead from '../../PresenterHead';
import ReactionResult from './ReactionResult';

const ReactionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ReactionsResults = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 100px;
  width: min(500px, 90%);
`;

const ReactionPresenter = ({ module }) => {
  const [data, { loading }] = useModuleUpdateQuery(
    module.id,
    VOTE_RESULTS,
    useMemo(
      () => ({ module_id: module.id }),
      [module.id],
    ),
  );

  const reactions = useMemo(() => {
    if (loading) return [];
    if (!data) return [];
    if (!data.voteResults) return [];
    if (!data.voteResults.options) return [];

    return data.voteResults.options.reduce((items, item) => {
      const result = module.options.find(({ id }) => id === item.id);
      if (!result) return items;

      return [...items, {
        id: result.id,
        image: result.image,
        option: item.option,
        count: item.totalCount,
      }];
    }, [])
      .sort(({ id: a }, { id: b }) => a - b);
  }, [loading, data, module]);

  const hasEmpty = reactions.some((item) => !item.image);
  if (hasEmpty) {
    console.log('has empty reaction', { reactions });
  }

  return (
    <ReactionsContainer>
      <PresenterHead
        title={module.title}
        subtitle={module.subtitle}
      />
      <ReactionsResults>
        {reactions.map(({ id, count, image }) => (
          <ReactionResult
            key={id}
            count={count}
            image={image}
          />
        ))}
      </ReactionsResults>
    </ReactionsContainer>
  );
};

export default ReactionPresenter;
